import { BaseConverter } from '../../../core/BaseConverter';
import { determineExchangeRateAndCurrency } from '../../../utils/determiners';
import { formatAmount, formatDate, formatTime, parseAmountValue, flipExchangeRate } from '../../../utils/formatters';
import { validateCurrency } from '../../../utils/validators';

export class InteractiveBrokersExpenseConverter extends BaseConverter {
  constructor(csvContent, selectedBroker, options = {}) {
    const defaultOptions = {
      dateField: 'Date/Time',
      timeField: 'Date/Time',
      typeField: 'Type',
      ...options
    };
    super(csvContent, selectedBroker, defaultOptions);
  }

  filterData(data) {
    if (!Array.isArray(data)) {
      return [];
    }

    return data.filter(row => {
      if (!row[this.options.dateField]) {
        return false;
      }

      const type = (row[this.options.typeField] || '').toLowerCase();
      const description = (row.Description || '').toLowerCase();
      
      // Exclude dividend-related taxes as they're handled by dividend converter
      if (type === 'withholding tax' && description.includes('dividend')) {
        return false;
      }

      return ['withholding tax', 'broker interest paid', 'broker interest received'].includes(type);
    });
  }

  transformData(data) {
    return data.map(row => {
      const amount = parseAmountValue(row.Amount || '');
      const assetCurrency = row.CurrencyPrimary || row.Currency || '';

      if (amount === 0) return null;

      // Parse date and time from Date/Time column
      const dateTime = row[this.options.dateField] || '';
      const date = formatDate(dateTime);
      const time = formatTime(dateTime);

      // Determine exchange rate and currency
      const { rate: formattedExchangeRate, currency: exchangeRateCurrency } = 
        determineExchangeRateAndCurrency(assetCurrency, 'EUR', row.FXRateToBase || '1');

      return {
        broker: this.selectedBroker,
        date,
        time,
        description: row.Description || '',
        'expense-amount': formatAmount(-1 * amount, { allowNegative: true }),
        'expense-amount-currency': validateCurrency(assetCurrency),
        'expense-amount-exchange-rate': flipExchangeRate(formattedExchangeRate),
        'expense-amount-exchange-rate-currency': exchangeRateCurrency
      };
    }).filter(Boolean);
  }
}
