import { BaseConverter } from '../../../core/BaseConverter';
import { determineExchangeRateAndCurrency } from '../../../utils/determiners';
import { formatAmount, formatDate, formatTime, parseAmountValue } from '../../../utils/formatters';
import { mapExchange } from '../../../config/exchangeMapping';
import { COLUMN_RULES } from '../../../config/columnRules';
import { validateCurrency } from '../../../utils/validators';
import { parseDateTimeString } from '../../../utils/dateTimeParser';

export class DEGIROExpenseConverter extends BaseConverter {
  constructor(csvContent, selectedBroker, options = {}) {
    const degiroOptions = {
      dateField: 'Datum',
      timeField: 'Tijd',
      ...options
    };
    super(csvContent, selectedBroker, degiroOptions);
  }

  filterData(data) {
    // Skip header row and filter expense entries
    return data.slice(1).filter(row => {
      return row && 
             row.Datum && // Date
             row.Product && // Name
             row.ISIN && // ISIN
             row.Beurs && // Exchange
             row.Omschrijving && // Description
             (row.Omschrijving.toLowerCase().includes('kosten') || 
              row.Omschrijving.toLowerCase().includes('costs') ||
              row.Omschrijving.toLowerCase().includes('fee')) &&
             row.Totaal && // Amount
             !isNaN(parseFloat(String(row.Totaal).replace(',', '.')));
    });
  }

  transformData(data) {
    return data.map(row => {
      // Combine date and time fields
      const dateTime = parseDateTimeString(`${row[this.options.dateField]} ${row[this.options.timeField]}`);

      // Extract and validate amount
      const amount = parseAmountValue(row.Totaal || '0');
      const assetCurrency = row._4 || '';
      const exchangeRate = row.Wisselkoers || '';

      // Determine exchange rate and currency
      const { rate: formattedExchangeRate, currency: exchangeRateCurrency } = 
        determineExchangeRateAndCurrency(assetCurrency, 'EUR', exchangeRate);

      const output = {
        broker: this.selectedBroker,
        name: row.Product || '',
        type: 'Expense',
        search: row.ISIN || '',
        exchange: mapExchange(row.Beurs || ''),
        date: formatDate(dateTime),
        time: formatTime(dateTime),
        description: row.Omschrijving || '',
        'expense-amount': formatAmount(Math.abs(amount)),
        'expense-amount-currency': validateCurrency(assetCurrency) ? assetCurrency : '',
        'expense-amount-exchange-rate': formattedExchangeRate,
        'expense-amount-exchange-rate-currency': exchangeRateCurrency
      };

      // Validate output against column rules
      const rules = { ...COLUMN_RULES.common, ...COLUMN_RULES.expense };
      for (const [field, rule] of Object.entries(rules)) {
        if (rule.validation && !rule.validation(output[field], output)) {
          return null;
        }
      }

      return output;
    }).filter(row => row !== null);
  }
}
