import { BaseConverter } from '../../../core/BaseConverter';
import { determineSecurityType, determineExchangeRateAndCurrency } from '../../../utils/determiners';
import { formatAmount, formatDate, formatTime, parseAmountValue } from '../../../utils/formatters';
import { parseDateTimeString } from '../../../utils/dateTimeParser';
import { validateCurrency } from '../../../utils/validators';
import { VALID_DIVIDEND_TYPES } from '../../../config/constants';

export class BitvavoDividendConverter extends BaseConverter {
  constructor(csvContent, selectedBroker, options = {}) {
    const bitvavoOptions = {
      dateField: 'Date',
      timeField: 'Time',
      dateTimeFormat: 'Europe/Amsterdam',
      typeField: 'Type',
      nameField: 'Currency',
      ...options
    };
    super(csvContent, selectedBroker, bitvavoOptions);
  }

  filterData(data) {
    return data.filter(row => {
      const type = row[this.options.typeField].toLowerCase();
      return type === 'staking';
    });
  }

  transformData(data) {
    return data.map(row => {
      const dateTime = parseDateTimeString(row[this.options.dateField] + ' ' + row[this.options.timeField], this.options.dateTimeFormat);
      const cryptoCurrency = row[this.options.nameField];
      const amount = parseAmountValue(row['Amount'] || '');

      return {
        broker: this.selectedBroker,
        name: cryptoCurrency || '',
        type: determineSecurityType(cryptoCurrency, cryptoCurrency), 
        search: cryptoCurrency || '',
        exchange: '',
        date: formatDate(dateTime),
        time: formatTime(dateTime),
        'dividend-type': VALID_DIVIDEND_TYPES[1], 
        'dividend-amount': formatAmount(amount),
        'dividend-amount-currency': '', 
        'dividend-amount-exchange-rate': '', 
        'dividend-amount-exchange-rate-currency': '', 
        'dividend-costs': '',
        'dividend-costs-currency': '',
        'dividend-costs-exchange-rate': '',
        'dividend-costs-exchange-rate-currency': '',
        'dividend-tax': '', 
        'dividend-tax-currency': '',
        'dividend-tax-exchange-rate': '',
        'dividend-tax-exchange-rate-currency': ''
      };
    });
  }
}
