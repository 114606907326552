import { BaseConverter } from '../../../core/BaseConverter';
import { determineExchangeRateAndCurrency } from '../../../utils/determiners';
import { formatAmount, formatDate, formatTime, parseAmountValue } from '../../../utils/formatters';
import { parseDateTimeString } from '../../../utils/dateTimeParser';
import { validateCurrency } from '../../../utils/validators';

export class BitvavoExpenseConverter extends BaseConverter {
  constructor(csvContent, selectedBroker, options = {}) {
    const bitvavoOptions = {
      dateField: 'Date',
      timeField: 'Time',
      dateTimeFormat: 'Europe/Amsterdam',
      typeField: 'Type',
      ...options
    };
    super(csvContent, selectedBroker, bitvavoOptions);
    this.knownCryptos = new Set();
  }

  filterData(data) {
    if (!Array.isArray(data)) {
      return [];
    }

    return data.filter(row => {
      const type = (row[this.options.typeField] || '').toLowerCase();
      const currency = row['Currency'];
      return type === 'affiliate' || (type === 'manually_assigned_bitvavo' && currency === 'EUR');
    });
  }

  transformData(data) {
    return data.map(row => {
      const dateTime = parseDateTimeString(
        `${row[this.options.dateField]} ${row[this.options.timeField]}`,
        this.options.dateTimeFormat
      );
      
      const amount = parseAmountValue(row.Amount || '0');
      
      const assetCurrency = row.Currency || '';
      const type = (row[this.options.typeField] || '').toLowerCase();
      const capitalizedType = type === 'manually_assigned_bitvavo' ? 'Manual Assignment' : 
                             type.charAt(0).toUpperCase() + type.slice(1);

      // Ensure amount is positive first
      const positiveAmount = Math.abs(amount);

      // Handle amount differently based on type - both affiliate and manual assignments are income
      const finalAmount = (type === 'affiliate' || type === 'manually_assigned_bitvavo') ? -1 * positiveAmount : positiveAmount;

      return {
        broker: this.selectedBroker,
        date: formatDate(dateTime),
        time: formatTime(dateTime),
        description: capitalizedType,
        'expense-amount': formatAmount(finalAmount, { allowNegative: true }),
        'expense-amount-currency': assetCurrency ? validateCurrency(assetCurrency) : '',
        'expense-amount-exchange-rate': '',
        'expense-amount-exchange-rate-currency': ''
      };
    }).filter(Boolean);
  }
}
