import { BaseConverter } from '../../../core/BaseConverter';
import { formatAmount, formatDate, formatTime, parseAmountValue } from '../../../utils/formatters';
import { validateCurrency } from '../../../utils/validators';
import { VALID_DIVIDEND_TYPES, VALID_SECURITY_TYPES } from '../../../config/constants';

export class AbnAmroDividendConverter extends BaseConverter {
  constructor(csvContent, selectedBroker, options = {}) {
    const defaultOptions = {
      dateField: 'Datum',
      nameField: 'Naam',
      amountField: 'Aantal/Bedrag',
      netValueField: 'Netto waarde',
      typeField: 'Type',
      orderTypeField: 'Order type',
      ...options
    };
    super(csvContent, selectedBroker, defaultOptions);
  }

  filterData(data) {
    if (!Array.isArray(data)) {
      return [];
    }

    return data.filter(row => {
      if (!row[this.options.dateField]) return false;

      const orderType = (row[this.options.orderTypeField] || '').toLowerCase();
      const netValue = parseAmountValue(row[this.options.netValueField] || '');
      const amount = parseAmountValue(row[this.options.amountField] || '');
      const type = (row[this.options.typeField] || '').toLowerCase();
      const name = (row[this.options.nameField] || '').toLowerCase();
      
      // Include:
      // 1. Explicit dividend entries (type=dividend or name includes dividend)
      // 2. CA transactions with positive net value
      // 3. CA transactions with "DIV" in name and non-zero amount
      return (type === 'dividend' || name.includes('dividend')) ||
             (orderType === 'ca' && (netValue > 0 || (name.includes('div') && amount > 0)));
    });
  }

  transformData(data) {
    return data.map(row => {
      // For dividend announcements, use the amount field instead of net value
      const netValue = parseAmountValue(row[this.options.netValueField] || '');
      const amount = netValue || parseAmountValue(row[this.options.amountField] || '');
      
      if (amount === 0) return null;

      const currency = row.Currency || 'EUR';

      return {
        broker: this.selectedBroker,
        type: VALID_SECURITY_TYPES[0],
        name: row[this.options.nameField] || '',
        search: '',
        exchange: '',
        date: formatDate(row[this.options.dateField]),
        time: formatTime("00:00"),
        'dividend-type': VALID_DIVIDEND_TYPES[0],
        'dividend-amount': formatAmount(amount, { allowNegative: true }),
        'dividend-amount-currency': currency ? validateCurrency(currency) : '',
        'dividend-amount-exchange-rate': '',
        'dividend-amount-exchange-rate-currency': '',
        'dividend-costs': '',
        'dividend-costs-currency': '',
        'dividend-costs-exchange-rate': '',
        'dividend-costs-exchange-rate-currency': '',
        'dividend-tax': '',
        'dividend-tax-currency': '',
        'dividend-tax-exchange-rate': '',
        'dividend-tax-exchange-rate-currency': ''
      };
    }).filter(Boolean);
  }
}
