import { BaseConverter } from '../../../core/BaseConverter';
import { determineExchangeRateAndCurrency } from '../../../utils/determiners';
import { formatAmount, formatDate, formatTime, parseAmountValue } from '../../../utils/formatters';
import { validateCurrency } from '../../../utils/validators';

export class EviVanLanschotExpenseConverter extends BaseConverter {
  constructor(csvContent, selectedBroker, options = {}) {
    const defaultOptions = {
      dateFields: ['Transactiedatum', 'Boekdatum'],
      typeField: 'Transactietype',
      delimiter: ';',
      type: 'expenses'
    };
    super(csvContent, selectedBroker, { ...defaultOptions, ...options });

    this.validExpenseTypes = {
      'kosten': 'General broker costs',
      'service fee': 'Periodic service fee for account maintenance',
      'rente betaling': 'Interest payment',
      'roerende voorheffing op rente': 'Withholding tax on interest'
    };
  }

  getDateValue(row) {
    for (const field of this.options.dateFields) {
      if (row[field]) {
        return row[field];
      }
    }
    return null;
  }

  filterData(data) {
    return data.filter(row => {
      const date = this.getDateValue(row);
      if (!date) {
        return false;
      }

      const type = row[this.options.typeField]?.toLowerCase() || '';
      const accountType = row['Rekeningtype']?.toLowerCase() || '';
      const transactionType = row['Soort transactie']?.toLowerCase() || '';
      
      // Accept both account types
      const validAccountTypes = ['zichtrekening', 'effectenrekening', 'portefeuille', 'effectendepot'];
      const isValidAccountType = validAccountTypes.includes(accountType);

      return isValidAccountType && 
             (transactionType === 'effecten' || transactionType === 'liquiditeiten') &&
             Object.keys(this.validExpenseTypes).includes(type);
    });
  }

  transformData(data) {
    return data.map(row => {
      const amount = parseAmountValue(row['Transactiebedrag'] || row['Bedrag'] || '');
      if (amount === 0) {
        return null;
      }

      const type = row[this.options.typeField]?.toLowerCase() || '';
      const assetCurrency = row['Transactiebedrag valuta'] || 'EUR';
      const description = row['Omschrijving'] || '';
      const exchangeRate = row['Wisselkoers'] || '';

      // Ensure amount is positive first
      const positiveAmount = Math.abs(amount);
      
      // Handle amount differently based on type
      const finalAmount = type === 'rente betaling' ? -1 * positiveAmount : positiveAmount;

      // Determine exchange rate and currency
      const { rate: formattedExchangeRate, currency: exchangeRateCurrency } = 
        determineExchangeRateAndCurrency(assetCurrency, 'EUR', exchangeRate);

      return {
        broker: this.selectedBroker,
        date: formatDate(this.getDateValue(row)),
        time: formatTime('00:00'),
        description: description,
        'expense-amount': formatAmount(finalAmount, { allowNegative: true }),
        'expense-amount-currency': assetCurrency ? validateCurrency(assetCurrency) : '',
        'expense-amount-exchange-rate': formatAmount(formattedExchangeRate),
        'expense-amount-exchange-rate-currency': exchangeRateCurrency ? validateCurrency(exchangeRateCurrency) : ''
      };
    }).filter(Boolean);
  }
}
