import { BaseConverter } from '../../../core/BaseConverter';
import { determineExchangeRateAndCurrency } from '../../../utils/determiners';
import { formatAmount, formatDate, formatTime, parseAmountValue } from '../../../utils/formatters';
import { validateCurrency } from '../../../utils/validators';

export class Trading212ExpenseConverter extends BaseConverter {
  constructor(csvContent, selectedBroker, options = {}) {
    const defaultOptions = {
      dateField: 'Time',
      timeField: 'Time',
      typeField: 'Action',
      ...options
    };
    super(csvContent, selectedBroker, defaultOptions);
  }

  filterData(data) {
    if (!Array.isArray(data)) {
      return [];
    }

    return data.filter(row => {
      if (!row[this.options.dateField]) {
        return false;
      }

      const action = (row[this.options.typeField] || '').toLowerCase();
      
      // Skip fees that are part of transactions (buy/sell)
      if (action.includes('market buy') || action.includes('market sell')) {
        return false;
      }

      // Handle standalone fees and interest
      return action.includes('interest') || 
             (action.includes('fee') && row['Currency conversion fee'] && parseFloat(row['Currency conversion fee']) !== 0) ||
             (row['Finra fee'] && parseFloat(row['Finra fee']) !== 0);
    });
  }

  transformData(data) {
    return data.map(row => {
      // Parse date and time
      const dateTime = row[this.options.dateField] || '';
      const [datePart, timePart] = dateTime.split(' ');
      const time = timePart ? timePart.split('.')[0] : '00:00';

      const action = (row[this.options.typeField] || '').toLowerCase();
      const exchangeRate = parseAmountValue(row['Exchange rate'] || '1');
      const totalCurrency = validateCurrency(row['Currency (Total)'] || '');

      // Handle different fee types
      let amount = 0;
      let feeCurrency = '';
      let description = '';

      if (action.includes('interest')) {
        amount = parseAmountValue(row['Total'] || '0');
        feeCurrency = totalCurrency;
        description = 'Interest';
      } else if (row['Currency conversion fee'] && parseFloat(row['Currency conversion fee']) !== 0) {
        amount = parseAmountValue(row['Currency conversion fee'] || '0');
        feeCurrency = validateCurrency(row['Currency (Currency conversion fee)'] || totalCurrency);
        description = 'Currency conversion fee';
      } else if (row['Finra fee'] && parseFloat(row['Finra fee']) !== 0) {
        amount = parseAmountValue(row['Finra fee'] || '0');
        feeCurrency = validateCurrency(row['Currency (Finra fee)'] || totalCurrency);
        description = 'Finra fee';
      }

      if (amount === 0) return null;

      const { rate: feeExchangeRate, currency: feeExchangeRateCurrency } = determineExchangeRateAndCurrency(
        feeCurrency,
        totalCurrency,
        exchangeRate
      );

      return {
        broker: this.broker,
        date: formatDate(datePart),
        time: formatTime(time),
        description,
        'expense-amount': formatAmount(-1 * amount, { allowNegative: true }),
        'expense-amount-currency': feeCurrency ? validateCurrency(feeCurrency) : '',
        'expense-amount-exchange-rate': feeExchangeRate ? formatAmount(feeExchangeRate) : '',
        'expense-amount-exchange-rate-currency': feeExchangeRateCurrency ? validateCurrency(feeExchangeRateCurrency) : ''
      };
    }).filter(Boolean);
  }
}
