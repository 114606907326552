import { VALID_TRANSACTION_ACTIONS, VALID_BOOKING_ACTIONS, VALID_SECURITY_TYPES, VALID_COMMODITIES } from '../config/constants';
import { formatAmount, parseAmountValue } from './formatters';
import { validateCurrency } from './validators';

/**
 * Determines the transaction action (buy/sell) based on a boolean flag
 * @param {boolean} isBuy - Whether the transaction is a buy
 * @returns {string} The transaction action
 */
export const determineTransactionAction = (isBuy) => {
  const action = isBuy ? VALID_TRANSACTION_ACTIONS[0] : VALID_TRANSACTION_ACTIONS[1];
  return action;
};

/**
 * Determines the booking action based on the action string and amount
 * @param {string} action - The action string to analyze
 * @param {number} amount - The amount involved in the booking
 * @returns {string} The determined booking action
 */
export const determineBookingAction = (action, amount = 0) => {
  if (!action) return '';
  
  // First try exact match
  const validAction = VALID_BOOKING_ACTIONS.find(
    valid => valid.toLowerCase() === action.toLowerCase()
  );
  if (validAction) return validAction;
  
  // Then try to determine from keywords
  const lowerAction = action.toLowerCase();
  
  // Determine from keywords or amount
  if (lowerAction.includes('deposit') || amount > 0) return VALID_BOOKING_ACTIONS[0];
  if (lowerAction.includes('withdrawal') || amount < 0) return VALID_BOOKING_ACTIONS[1];
  
  return '';
};

/**
 * Determines the security type based on asset name and category
 * @param {string} assetName - The name of the asset
 * @param {string} assetCategory - The category of the asset
 * @returns {string} The determined security type
 */
export const determineSecurityType = (assetName = '', assetCategory = '') => {
  if (!assetName && !assetCategory) {
    return VALID_SECURITY_TYPES[0]; // 'Stock market'
  }

  const lowerName = assetName.toLowerCase();
  const lowerCategory = assetCategory.toLowerCase();

  // Check for ISIN first - if present, it's a stock market security
  if (assetCategory.match(/^[A-Z]{2}[A-Z0-9]{9}\d$/)) {
    return VALID_SECURITY_TYPES[0]; // 'Stock market'
  }

  // Check for crypto first - if it's a 3-5 letter symbol, it's likely crypto
  if (assetName.match(/^[A-Z]{3,5}$/)) {
    return VALID_SECURITY_TYPES[1]; // 'Crypto'
  }

  // Check for crypto keywords
  if (lowerCategory.includes('crypto') || 
      lowerName.includes('bitcoin') || 
      lowerName.includes('ethereum') ||
      lowerCategory.match(/^[a-z]{3,5}$/)) {
    return VALID_SECURITY_TYPES[1]; // 'Crypto'
  }

  // Check for commodities and validate against VALID_COMMODITIES
  if (lowerCategory.includes('commodit') || 
      VALID_COMMODITIES.some(metal => lowerName.includes(metal.toLowerCase()))) {
    return VALID_SECURITY_TYPES[2]; // 'Commodity'
  }

  // Default to stock market for regular shares
  return VALID_SECURITY_TYPES[0]; // 'Stock market'
};

/**
 * Determines if an exchange rate and its currency should be shown based on currencies involved
 * @param {string} baseCurrency - The currency being converted from
 * @param {string} quoteCurrency - The currency being converted to
 * @param {string|number} exchangeRate - The exchange rate value
 * @returns {{rate: string, currency: string}} Object containing formatted exchange rate and currency
 */
export const determineExchangeRateAndCurrency = (baseCurrency, quoteCurrency, exchangeRate) => {
  // If currencies are the same, no exchange rate needed
  if (baseCurrency === quoteCurrency) {
    return { rate: '', currency: '' };
  }

  // If exchange rate is empty or invalid, return empty
  if (!exchangeRate) {
    return { rate: '', currency: '' };
  }

  // Format and validate the exchange rate
  const formattedRate = typeof exchangeRate === 'string' ? 
    formatAmount(parseAmountValue(exchangeRate)) : 
    formatAmount(exchangeRate);

  // Return formatted rate and validated currency
  return {
    rate: formattedRate,
    currency: validateCurrency(quoteCurrency) ? quoteCurrency : ''
  };
};
