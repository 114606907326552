import BaseCsvPreprocessor from './BaseCsvPreprocessor.js';

class AbnAmroCsvPreprocessor extends BaseCsvPreprocessor {
    constructor() {
        super();
    }

    /**
     * Parse a CSV line that may contain quoted fields
     * @param {string} line - CSV line to parse
     * @returns {string[]} - Array of fields
     */
    parseCSVLine(line) {
        // First format: Double quotes with escaped quotes
        if (line.startsWith('"') && line.includes('""')) {
            // Remove the outer quotes if they exist
            line = line.replace(/^"|"$/g, '');
            
            // Split by double quotes followed by comma
            const fields = line.split('","').map(field => {
                // Remove any remaining double quotes and trim
                return field.replace(/""/g, '"').trim();
            });
            
            return fields;
        }
        // Second format: Simple quoted fields
        else if (line.includes('"')) {
            // Split by comma while preserving commas in quotes
            const fields = line.split(/,(?=(?:[^"]*"[^"]*")*[^"]*$)/);
            return fields.map(field => {
                // Remove quotes and trim
                return field.replace(/^"|"$/g, '').trim();
            });
        }
        // No quotes: simple split
        else {
            return line.split(',').map(field => field.trim());
        }
    }

    /**
     * Format a field value
     * @param {string} value - Field value to format
     * @param {number} index - Field index
     * @returns {string} - Formatted value
     */
    formatField(value, index) {
        // Skip date field (index 0)
        if (index === 0) {
            return value;
        }
        
        // Handle special case for '--,--'
        if (value === '--,--') {
            return '0.00';
        }

        // If the field contains a comma and periods (second format)
        if (value.includes(',') && value.includes('.')) {
            // Remove thousand separators (periods)
            const withoutThousands = value.replace(/\./g, '');
            // Replace comma with period for decimal
            return withoutThousands.replace(',', '.');
        }
        
        // If the field only contains a comma (no periods)
        if (value.includes(',') && !value.includes('.')) {
            const potentialNumber = value.replace(',', '.');
            if (!isNaN(parseFloat(potentialNumber))) {
                return potentialNumber;
            }
        }
        
        return value;
    }

    /**
     * Preprocess ABN AMRO CSV content
     * @param {string} csvContent - Raw CSV content
     * @returns {string} - Preprocessed CSV content
     */
    preprocess(csvContent) {
        if (!this.validateFormat(csvContent)) {
            throw new Error('Invalid ABN AMRO CSV format');
        }

        // Detect line ending
        const lineEnding = csvContent.includes('\r\n') ? '\r\n' : '\n';
        
        // Split into lines
        const lines = csvContent.trim().split(/\r?\n/);
        const header = lines[0]; // Keep header as is
        
        // Process data lines
        const processedLines = lines.slice(1).map(line => {
            if (!line.trim()) return ''; // Skip empty lines
            
            // Parse and format each line
            const fields = this.parseCSVLine(line);
            const processedFields = fields.map((field, index) => this.formatField(field, index));
            
            return processedFields.join(',');
        }).filter(line => line); // Remove empty lines

        // Join with the original line ending
        return [header, ...processedLines].join(lineEnding);
    }

    /**
     * Validate if the content matches ABN AMRO's CSV format
     * @param {string} csvContent - Raw CSV content
     * @returns {boolean} - True if the format is valid
     */
    validateFormat(csvContent) {
        if (!csvContent || typeof csvContent !== 'string') {
            return false;
        }

        const lines = csvContent.trim().split(/\r?\n/);
        if (lines.length < 2) { // Need at least header and one data line
            return false;
        }

        // Check header
        const expectedHeader = 'Datum,Naam,Netto waarde,Aantal/Bedrag,Type,Koers,Order type';
        const header = lines[0].replace(/"/g, ''); // Remove quotes if present
        return header === expectedHeader;
    }
}

export default AbnAmroCsvPreprocessor;
