import { BaseConverter } from '../../../core/BaseConverter';
import { determineSecurityType, determineExchangeRateAndCurrency } from '../../../utils/determiners';
import { formatAmount, formatDate, formatTime, parseAmountValue } from '../../../utils/formatters';
import { parseDateTimeString } from '../../../utils/dateTimeParser';
import { validateCurrency } from '../../../utils/validators';
import { VALID_DIVIDEND_TYPES } from '../../../config/constants';

export class BUXDividendConverter extends BaseConverter {
  constructor(csvContent, selectedBroker, options = {}) {
    const buxOptions = {
      dateTimeField: 'Transaction Time (CET)',
      categoryField: 'Transaction Category',
      typeField: 'Transaction Type',
      amountField: 'Transaction Amount',
      ...options
    };
    super(csvContent, selectedBroker, buxOptions);
  }

  filterData(data) {
    if (!Array.isArray(data)) {
      return [];
    }

    return data.filter(row => {
      const category = (row[this.options.categoryField] || '').toLowerCase();
      const type = (row[this.options.typeField] || '').toLowerCase();

      return (
        (category.includes('corporate') || category.includes('dividend')) &&
        (type.includes('dividend') || type.includes('capital') || type.includes('return'))
      );
    });
  }

  transformData(data) {
    return data.map(row => {
      const dateTime = parseDateTimeString(row[this.options.dateTimeField]);
      const amount = parseAmountValue(row[this.options.amountField] || '0');
      const exchangeRate = row['Exchange Rate'] || '';
      const assetId = row['Asset Id'] || '';
      const assetName = row['Asset Name'] || '';
      const assetCurrency = row['Asset Currency'] || '';

      // Determine exchange rate and currency
      const { rate: formattedExchangeRate, currency: exchangeRateCurrency } = 
        determineExchangeRateAndCurrency(assetCurrency, 'EUR', exchangeRate);

      const securityType = determineSecurityType(assetName, assetId);
      const transactionType = (row[this.options.typeField] || '').toLowerCase();

      // Determine dividend type based on transaction type
      let dividendType;
      if (transactionType.includes('stock')) {
        dividendType = VALID_DIVIDEND_TYPES[1]; // 'Stock'
      } else if (transactionType.includes('staking')) {
        dividendType = VALID_DIVIDEND_TYPES[2]; // 'Staking'
      } else {
        dividendType = VALID_DIVIDEND_TYPES[0]; // Default to 'Cash' for other types
      }

      return {
        broker: this.selectedBroker,
        name: assetName,
        type: securityType,
        search: assetId,
        exchange: '',
        date: formatDate(dateTime),
        time: formatTime(dateTime),
        'dividend-type': dividendType,
        'dividend-amount': formatAmount(amount, { allowNegative: true }),
        'dividend-amount-currency': validateCurrency(assetCurrency) ? assetCurrency : '',
        'dividend-amount-exchange-rate': formatAmount(formattedExchangeRate),
        'dividend-amount-exchange-rate-currency': exchangeRateCurrency,
      };
    });
  }

  determineDividendType(type) {
    type = (type || '').toLowerCase();
    
    if (type.includes('capital return')) return 'Capital Return';
    if (type.includes('dividend')) {
      if (type.includes('special')) return 'Special Dividend';
      return 'Regular Dividend';
    }
    return 'Other';
  }
}
