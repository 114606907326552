import { BaseConverter } from '../../../core/BaseConverter';
import { determineBookingAction } from '../../../utils/determiners';
import { formatAmount, formatDate, formatTime, parseAmountValue } from '../../../utils/formatters';
import { validateCurrency } from '../../../utils/validators';
import { parseDateTimeString } from '../../../utils/dateTimeParser';

export class BitvavoBookingConverter extends BaseConverter {
  constructor(csvContent, selectedBroker, options = {}) {
    const bitvavoOptions = {
      dateField: 'Date',
      timeField: 'Time',
      dateTimeFormat: 'Europe/Amsterdam',
      typeField: 'Type',
      ...options
    };
    super(csvContent, selectedBroker, bitvavoOptions);
    this.knownCryptos = new Set();
  }

  // It is possible that a booking refers to a crypto, we handle this as a transaction
  // Helper method to identify known cryptocurrencies from buy/sell transactions
  findKnownCryptos(data) {
    data.forEach(row => {
      const type = (row[this.options.typeField] || '').toLowerCase();
      if (['buy', 'sell'].includes(type)) {
        this.knownCryptos.add(row['Currency']);
      }
    });
  }

  filterData(data) {
    // First identify all cryptocurrencies that have been traded
    this.findKnownCryptos(data);

    return data.filter(row => {
      const type = (row[this.options.typeField] || '').toLowerCase();
      const currency = row['Currency'];

      // For deposits and withdrawals, only include if it's not a known crypto
      if (type === 'deposit' || type === 'withdrawal') {
        return !this.knownCryptos.has(currency);
      }

      return false;
    });
  }

  transformData(data) {
    return data.map(row => {
      const dateTime = parseDateTimeString(
        `${row[this.options.dateField]} ${row[this.options.timeField]}`,
        this.options.dateTimeFormat
      );
      const type = (row[this.options.typeField] || '').toLowerCase();
      const amount = parseAmountValue(row['Amount'] || '0');
      const currency = row['Currency'];
      
      return {
        broker: this.selectedBroker,
        date: formatDate(dateTime),
        time: formatTime(dateTime),
        'booking-action': determineBookingAction(type, amount),
        'booking-amount': formatAmount(amount),
        'booking-amount-currency': validateCurrency(currency) ? currency : '',
      };
    });
  }
}
