import { BaseConverter } from '../../../core/BaseConverter';
import { determineBookingAction } from '../../../utils/determiners';
import { formatAmount, formatDate, formatTime, parseAmountValue } from '../../../utils/formatters';
import { validateCurrency } from '../../../utils/validators';

export class InteractiveBrokersBookingConverter extends BaseConverter {
  constructor(csvContent, selectedBroker, options = {}) {
    const defaultOptions = {
      dateField: 'Date/Time',
      timeField: 'Date/Time',
      typeField: 'Type',
      ...options
    };
    super(csvContent, selectedBroker, defaultOptions);
  }

  filterData(data) {
    if (!Array.isArray(data)) {
      return [];
    }

    return data.filter(row => {
      if (!row[this.options.dateField]) {
        return false;
      }

      const type = (row[this.options.typeField] || '').toLowerCase();
      return type === 'deposits/withdrawals';
    });
  }

  transformData(data) {
    return data.map(row => {
      const amount = parseAmountValue(row.Amount || '');
      const assetCurrency = row.CurrencyPrimary || row.Currency || '';

      if (amount === 0) return null;

      // Parse date and time from Date/Time column
      const dateTime = row[this.options.dateField] || '';
      const date = formatDate(dateTime);
      const time = formatTime(dateTime);

      const action = determineBookingAction(row[this.options.typeField] || '');

      return {
        broker: this.selectedBroker,
        date,
        time,
        name: row.Symbol || '',
        search: row.ISIN || '',
        'booking-action': action,
        'booking-amount': formatAmount(amount),
        'booking-amount-currency': validateCurrency(assetCurrency),
        description: row.Description || ''
      };
    }).filter(Boolean);
  }
}
