import { BaseConverter } from '../../../core/BaseConverter';
import { determineSecurityType, determineExchangeRateAndCurrency, determineTransactionAction } from '../../../utils/determiners';
import { formatAmount, formatDate, formatTime, parseAmountValue } from '../../../utils/formatters';
import { parseDateTimeString } from '../../../utils/dateTimeParser';
import { validateCurrency } from '../../../utils/validators';

export class BitvavoTransactionConverter extends BaseConverter {
  constructor(csvContent, selectedBroker, options = {}) {
    const bitvavoOptions = {
      dateField: 'Date',
      timeField: 'Time',
      dateTimeFormat: 'Europe/Amsterdam',
      typeField: 'Type',
      ...options
    };
    super(csvContent, selectedBroker, bitvavoOptions);
    this.knownCryptos = new Set();
  }

  // Helper method to identify known cryptocurrencies from buy/sell transactions
  findKnownCryptos(data) {
    data.forEach(row => {
      const type = (row[this.options.typeField] || '').toLowerCase();
      if (['buy', 'sell'].includes(type)) {
        this.knownCryptos.add(row['Currency']);
      }
    });
  }

  filterData(data) {
    // First identify all cryptocurrencies that have been traded
    this.findKnownCryptos(data);

    return data.filter(row => {
      const type = (row[this.options.typeField] || '').toLowerCase();
      const currency = row['Currency'];
      const feeCurrency = row['Fee currency'];
      const feeAmount = parseFloat(row['Fee amount']) || 0;
      
      // Include regular buy/sell transactions
      if (['buy', 'sell'].includes(type)) {
        return true;
      }

      // Include manual assignments for cryptocurrencies
      if (type === 'manually_assigned_bitvavo' && currency !== 'EUR') {
        return true;
      }

      // Include deposits and withdrawals of known cryptocurrencies
      if ((type === 'withdrawal' || type === 'deposit') && this.knownCryptos.has(currency)) {
        return true;
      }

      // Include deposit and withdrawal fees of known cryptocurrencies
      if ((type === 'withdrawal' || type === 'deposit') && feeAmount > 0 && this.knownCryptos.has(feeCurrency)) {
        return true;
      }

      return false;
    });
  }

  transformData(data) {
    return data.map(row => {
      const dateTime = parseDateTimeString(
        `${row[this.options.dateField]} ${row[this.options.timeField]}`,
        this.options.dateTimeFormat
      );
      const type = (row[this.options.typeField] || '').toLowerCase();
      const cryptoCurrency = row['Currency'] || '';
      const feeCurrency = row['Fee currency'] || '';

      // Determine if this is a withdrawal/deposit-related transaction
      const isWithdrawalFee = type === 'withdrawal' && 
                             parseFloat(row['Fee amount']) > 0 && 
                             this.knownCryptos.has(feeCurrency);
      const isDepositFee = type === 'deposit' && 
                          parseFloat(row['Fee amount']) > 0 && 
                          this.knownCryptos.has(feeCurrency);
      const isWithdrawal = type === 'withdrawal' && this.knownCryptos.has(cryptoCurrency);
      const isDeposit = type === 'deposit' && this.knownCryptos.has(cryptoCurrency);
      const isManualAssignment = type === 'manually_assigned_bitvavo';

      // Use fee amount and currency if it's a fee transaction
      const amount = (isWithdrawalFee || isDepositFee) ? 
        parseAmountValue(row['Fee amount'] || '0') : 
        parseAmountValue(row['Amount'] || '0');
            
      // Set price and fees based on transaction type
      let price = '0';
      if (!isWithdrawal && !isWithdrawalFee && !isDeposit && !isDepositFee && !isManualAssignment) {
        const rawPrice = parseAmountValue(row['Price (EUR)'] || '0');
        price = rawPrice;
      }
      
      // Handle transaction costs
      let fee = '';
      let feeCurrencyOutput = '';
      if (!isWithdrawal && !isWithdrawalFee && !isDeposit && !isDepositFee && !isManualAssignment) {
        const feeAmount = parseAmountValue(row['Fee amount'] || '0');
        const rawFeeCurrency = row['Fee currency'];
        if (feeAmount > 0 && rawFeeCurrency) {
          fee = formatAmount(feeAmount);
          feeCurrencyOutput = rawFeeCurrency ? validateCurrency(rawFeeCurrency) : '';
        }
      }

      // Treat withdrawals and withdrawal fees as sell actions
      // Treat deposits and deposit fees as buy actions
      const isSell = type === 'sell' || isWithdrawal || isWithdrawalFee;

      return {
        broker: this.selectedBroker,
        name: cryptoCurrency,
        type: determineSecurityType(cryptoCurrency, cryptoCurrency), // Pass currency as both name and category
        search: cryptoCurrency,
        exchange: '',
        date: formatDate(dateTime),
        time: formatTime(dateTime),
        'transaction-action': determineTransactionAction(!isSell),
        'transaction-amount': formatAmount(amount),
        'transaction-price': formatAmount(price),
        'transaction-price-currency': validateCurrency('EUR'),
        'transaction-price-exchange-rate': '',
        'transaction-price-exchange-rate-currency': '',
        'transaction-costs': fee,
        'transaction-costs-currency': feeCurrencyOutput,
        'transaction-costs-exchange-rate': '',
        'transaction-costs-exchange-rate-currency': '',
        'transaction-tax': '',
        'transaction-tax-currency': '',
        'transaction-tax-exchange-rate': '',
        'transaction-tax-exchange-rate-currency': ''
      };
    });
  }
}
