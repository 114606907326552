import { BaseConverter } from '../../../core/BaseConverter';
import { formatAmount, formatDate, formatTime, parseAmountValue } from '../../../utils/formatters';
import { determineSecurityType, determineExchangeRateAndCurrency } from '../../../utils/determiners';
import { validateCurrency } from '../../../utils/validators';
import { VALID_DIVIDEND_TYPES } from '../../../config/constants';

export class EviVanLanschotDividendConverter extends BaseConverter {
  constructor(csvContent, selectedBroker, options = {}) {
    const defaultOptions = {
      dateFields: ['Transactiedatum', 'Boekdatum'],
      typeField: 'Transactietype',
      delimiter: ';',
      type: 'dividends',
      nameField: 'Fonds',
      timeField: ''
    };
    super(csvContent, selectedBroker, { ...defaultOptions, ...options });
  }

  getDateValue(row) {
    for (const field of this.options.dateFields) {
      if (row[field]) {
        return row[field];
      }
    }
    return null;
  }

  filterData(data) {
    // First get all valid rows
    const validRows = data.filter(row => {
      const date = this.getDateValue(row);
      if (!date) {
        return false;
      }

      const type = row[this.options.typeField]?.toLowerCase() || '';
      const accountType = row['Rekeningtype']?.toLowerCase() || '';
      const transactionType = row['Soort transactie']?.toLowerCase() || '';
      
      // Accept Portefeuille, Effectendepot and Effectenrekening
      const validAccountTypes = ['portefeuille', 'effectendepot', 'effectenrekening'];
      const isValidAccountType = validAccountTypes.includes(accountType);

      // For Effectenrekening, we accept Liquiditeiten transactions
      const isValidTransactionType = accountType === 'effectenrekening' ? 
        transactionType === 'liquiditeiten' :
        transactionType === 'effecten';

      return isValidAccountType && 
             isValidTransactionType &&
             (type === 'dividend' || 
              (type === 'stukken inboeking' && row['Omschrijving']?.toLowerCase().includes('ontvangst')));
    });

    // Then deduplicate based on date and amount, preferring 'effecten' transactions
    const seen = new Map();
    
    // Sort rows to process 'effecten' transactions first
    const sortedRows = [...validRows].sort((a, b) => {
      const aType = (a['Soort transactie'] || '').toLowerCase();
      const bType = (b['Soort transactie'] || '').toLowerCase();
      // Put 'effecten' first
      if (aType === 'effecten' && bType !== 'effecten') return -1;
      if (bType === 'effecten' && aType !== 'effecten') return 1;
      return 0;
    });

    return sortedRows.filter(row => {
      const date = this.getDateValue(row);
      const couponAmount = parseAmountValue(row['Couponrente'] || '');
      const transactionAmount = parseAmountValue(row['Transactiebedrag'] || '');
      const amount = couponAmount || transactionAmount;
      const transactionType = (row['Soort transactie'] || '').toLowerCase();

      const key = `${date}_${amount}`;
      
      if (seen.has(key)) {
        // If we already have an 'effecten' entry, skip this one
        const existingType = seen.get(key);
        if (existingType === 'effecten') {
          return false;
        }
        // If this is an 'effecten' entry and the existing one isn't, keep this one
        if (transactionType === 'effecten') {
          seen.set(key, transactionType);
          return true;
        }
        return false;
      }
      
      seen.set(key, transactionType);
      return true;
    });
  }

  transformData(data) {
    return data.map(row => {
      const dateTime = this.getDateValue(row);
      const type = row[this.options.typeField].toLowerCase();
      const isStockDividend = type === 'stukken inboeking';
      
      let amount;
      let assetCurrency;
      
      if (isStockDividend) {
        // Stock dividend - use number of shares
        amount = parseAmountValue(row['Aantal / nominaal'] || row['Aantal'] || '');
        assetCurrency = ''; // No currency for stock dividends
      } else {
        // Cash dividend - try Couponrente first, then Transactiebedrag
        const couponAmount = parseAmountValue(row['Couponrente'] || '');
        const transactionAmount = parseAmountValue(row['Transactiebedrag'] || '');
        
        if (couponAmount > 0) {
          // Coupon dividend
          amount = couponAmount;
          assetCurrency = row['Couponrente Valuta'] || '';
        } else {
          // Regular cash dividend
          amount = transactionAmount;
          assetCurrency = row['Transactiebedrag valuta'] || '';
        }
      }
      
      if (amount === 0) {
        return null;
      }

      const assetName = row[this.options.nameField] || '';
      const assetId = row['ISIN'] || '';
      const exchangeRate = row['Wisselkoers'] || '';

      const internalCosts = parseAmountValue(row['Interne kosten'] || '');
      const externalCosts = parseAmountValue(row['Externe kosten'] || '');
      const totalCosts = internalCosts + externalCosts;
      const internalCostsValuta = row['Interne kosten valuta'] || '';
      const tax = parseAmountValue(row['Belasting'] || '');

      // Determine exchange rate and currency
      const { rate: formattedExchangeRate, currency: exchangeRateCurrency } = 
        determineExchangeRateAndCurrency(assetCurrency, 'EUR', exchangeRate);

      // Determine dividend type
      const dividendType = isStockDividend ? VALID_DIVIDEND_TYPES[1] : VALID_DIVIDEND_TYPES[0];

      return {
        broker: this.selectedBroker,
        name: assetName,
        isin: assetId,
        type: determineSecurityType(assetName, assetId),
        search: assetId,
        exchange: '',
        date: formatDate(dateTime),
        time: formatTime(dateTime),
        'dividend-type': dividendType,
        'dividend-amount': formatAmount(amount, { allowNegative: true }),
        'dividend-amount-currency': assetCurrency ? validateCurrency(assetCurrency) : '',
        'dividend-amount-exchange-rate': isStockDividend ? '' : formatAmount(formattedExchangeRate),
        'dividend-amount-exchange-rate-currency': isStockDividend ? '' : (exchangeRateCurrency ? validateCurrency(exchangeRateCurrency) : ''),
        'dividend-costs': totalCosts > 0 ? formatAmount(totalCosts) : '',
        'dividend-costs-currency': totalCosts > 0 ? (internalCostsValuta ? validateCurrency(internalCostsValuta) : '') : '',
        'dividend-costs-exchange-rate': totalCosts > 0 ? (exchangeRate ? formatAmount(exchangeRate) : '') : '',
        'dividend-costs-exchange-rate-currency': totalCosts > 0 ? (exchangeRate ? (exchangeRateCurrency ? validateCurrency(exchangeRateCurrency) : '') : '') : '',
        'dividend-tax': tax ? formatAmount(tax) : '',
        'dividend-tax-currency': tax ? (assetCurrency ? validateCurrency(assetCurrency) : '') : '',
        'dividend-tax-exchange-rate': tax ? (exchangeRate ? formatAmount(exchangeRate) : '') : '',
        'dividend-tax-exchange-rate-currency': tax ? (exchangeRate ? (exchangeRateCurrency ? validateCurrency(exchangeRateCurrency) : '') : '') : ''
      };
    }).filter(Boolean);
  }
}
