import { BaseConverter } from '../../../core/BaseConverter';
import { determineSecurityType, determineTransactionAction, determineExchangeRateAndCurrency } from '../../../utils/determiners';
import { formatAmount, formatDate, formatTime, parseAmountValue } from '../../../utils/formatters';
import { mapExchange } from '../../../config/exchangeMapping';
import { validateCurrency } from '../../../utils/validators';
import { parseDateTimeString } from '../../../utils/dateTimeParser';

export class DEGIROTransactionConverter extends BaseConverter {
  constructor(csvContent, selectedBroker, options = {}) {
    const degiroOptions = {
      dateField: 'Datum',
      timeField: 'Tijd',
      ...options
    };
    super(csvContent, selectedBroker, degiroOptions);
  }

  filterData(data) {
    if (!Array.isArray(data)) {
      return [];
    }

    return data.filter(row => {
      if (!row || !row[this.options.dateField]) {
        return false;
      }

      // Check if it's a transaction by verifying if Aantal (quantity) is present and not zero
      const quantity = parseAmountValue(row.Aantal || '0');
      return !isNaN(quantity) && quantity !== 0;
    });
  }

  transformData(data) {
    return data.map(row => {
      // Combine date and time fields
      const dateTime = parseDateTimeString(`${row[this.options.dateField]} ${row[this.options.timeField]}`);
      const quantity = parseAmountValue(row.Aantal);
      const isBuy = quantity > 0;
      const price = parseAmountValue(row.Koers);
      
      // Get currencies from unnamed columns that follow the amount columns
      const quantityCurrency = Object.values(row)[6] || '';  // Currency after 'Aantal'
      const priceCurrency = Object.values(row)[8] || '';  // Currency after 'Koers'
      const localValueCurrency = Object.values(row)[9] || '';  // Currency after 'Lokale waarde'
      
      const exchangeRate = row.Wisselkoers || '';

      // Determine exchange rate and currency
      const { rate: formattedExchangeRate, currency: exchangeRateCurrency } = 
        determineExchangeRateAndCurrency(localValueCurrency, 'EUR', exchangeRate);

      if (isNaN(quantity) || isNaN(price)) {
        return null;
      }

      const transactionCosts = parseAmountValue(row.Transactiekosten || '0');

      return {
        broker: this.selectedBroker,
        name: row.Product,
        type: determineSecurityType(row.Product, row.ISIN),
        search: row.ISIN || '',
        exchange: mapExchange(row.Beurs || ''),
        date: formatDate(dateTime),
        time: formatTime(dateTime),
        'transaction-action': determineTransactionAction(isBuy),
        'transaction-amount': formatAmount(quantity),
        'transaction-price': formatAmount(price),
        'transaction-price-currency': validateCurrency(priceCurrency) ? priceCurrency : '',
        'transaction-price-exchange-rate': formatAmount(formattedExchangeRate),
        'transaction-price-exchange-rate-currency': exchangeRateCurrency,
        'transaction-costs': transactionCosts ? formatAmount(transactionCosts) : '',
        'transaction-costs-currency': transactionCosts ? (validateCurrency('EUR') ? 'EUR' : '') : '',
        'transaction-costs-exchange-rate': '',
        'transaction-costs-exchange-rate-currency': '',
        'transaction-tax': '',
        'transaction-tax-currency': '',
        'transaction-tax-exchange-rate': '',
        'transaction-tax-exchange-rate-currency': ''
      };
    }).filter(row => row !== null);
  }
}
