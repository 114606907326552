import { BaseConverter } from '../../../core/BaseConverter';
import { determineSecurityType, determineTransactionAction, determineExchangeRateAndCurrency } from '../../../utils/determiners';
import { formatAmount, formatDate, formatTime, parseAmountValue } from '../../../utils/formatters';
import { validateCurrency } from '../../../utils/validators';

export class EviVanLanschotTransactionConverter extends BaseConverter {
  constructor(csvContent, selectedBroker, options = {}) {
    const defaultOptions = {
      dateFields: ['Transactiedatum', 'Boekdatum'],
      typeField: 'Transactietype',
      delimiter: ';',
      type: 'transactions'
    };
    super(csvContent, selectedBroker, { ...defaultOptions, ...options });
  }

  getDateValue(row) {
    for (const field of this.options.dateFields) {
      if (row[field]) {
        return row[field];
      }
    }
    return null;
  }

  filterData(data) {
    return data.filter(row => {
      const date = this.getDateValue(row);
      if (!date) {
        return false;
      }

      const type = row[this.options.typeField]?.toLowerCase() || '';
      const accountType = row['Rekeningtype']?.toLowerCase() || '';
      const transactionType = row['Soort transactie']?.toLowerCase() || '';
      
      // Accept both Portefeuille and Effectendepot
      const validAccountTypes = ['portefeuille', 'effectendepot'];
      const isValidAccountType = validAccountTypes.includes(accountType);

      return isValidAccountType && 
             transactionType === 'effecten' &&
             ((type === 'aankoop' || type === 'verkoop') ||
              (type === 'stukken inboeking' && row['Omschrijving']?.toLowerCase().includes('interne overboeking')));
    });
  }

  transformData(data) {
    return data.map(row => {
      const dateTime = this.getDateValue(row);
      const type = row[this.options.typeField]?.toLowerCase() || '';
      const quantity = parseAmountValue(row['Aantal / nominaal'] || row['Aantal'] || '');
      
      // Determine if it's a buy based on type or quantity for internal transfers
      const isBuy = type === 'stukken inboeking' ? 
        quantity > 0 : // For internal transfers, base it on quantity
        type === 'aankoop'; // For regular transactions, use the type

      const price = parseAmountValue(row['Koers'] || '');
      const exchangeRate = row['Wisselkoers'] || '';
      const assetCurrency = row['Transactiebedrag valuta'] || '';

      // Determine exchange rate and currency
      const { rate: formattedExchangeRate, currency: exchangeRateCurrency } = 
        determineExchangeRateAndCurrency(assetCurrency, 'EUR', exchangeRate);

      if (quantity === 0 || price === 0) {
        return null;
      }

      const assetName = row['Fonds'] || row['Naam'] || '';
      const assetId = row['ISIN'] || '';
      const internalCosts = parseAmountValue(row['Interne kosten'] || '');
      const externalCosts = parseAmountValue(row['Externe kosten'] || '');
      const totalCosts = internalCosts + externalCosts;
      const internalCostsValuta = parseAmountValue(row['Interne kosten valuta'] || '');
      const tax = parseAmountValue(row['Belasting'] || '');
      const taxValuta = parseAmountValue(row['Belasting valuta'] || '');

      return {
        broker: this.selectedBroker,
        name: assetName,
        type: determineSecurityType(assetName, assetId),
        search: assetId || assetName,
        exchange: '',  // Not provided in CSV
        date: formatDate(dateTime),
        time: formatTime(dateTime),
        'transaction-action': determineTransactionAction(isBuy),
        'transaction-amount': formatAmount(quantity),
        'transaction-price': formatAmount(price),
        'transaction-price-currency': assetCurrency ? validateCurrency(assetCurrency) : '',
        'transaction-price-exchange-rate': formatAmount(formattedExchangeRate),
        'transaction-price-exchange-rate-currency': validateCurrency(exchangeRateCurrency),
        'transaction-costs': totalCosts > 0 ? formatAmount(totalCosts) : '',
        'transaction-costs-currency': totalCosts > 0 ? (internalCostsValuta ?validateCurrency(internalCostsValuta) : '') : '',
        'transaction-costs-exchange-rate': totalCosts > 0 ? (exchangeRate ? formatAmount(exchangeRate) : '') : '',
        'transaction-costs-exchange-rate-currency': totalCosts > 0 ? (exchangeRate ? (exchangeRateCurrency ? validateCurrency(exchangeRateCurrency) : '') : '') : '',
        'transaction-tax': tax > 0 ? formatAmount(tax) : '',
        'transaction-tax-currency': tax > 0 ? (taxValuta ? validateCurrency(taxValuta) : '') : '',
        'transaction-tax-exchange-rate': tax > 0 ? (exchangeRate ? formatAmount(exchangeRate) : '') : '',
        'transaction-tax-exchange-rate-currency': tax > 0 ? (exchangeRate ?  (exchangeRateCurrency ? validateCurrency(exchangeRateCurrency) : '') : '') : ''
      };
    }).filter(Boolean);
  }
}
