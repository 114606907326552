import { BROKERS } from '../BrokerRegistry.js';
import AbnAmroCsvPreprocessor from './AbnAmroCsvPreprocessor.js';

class CsvPreprocessorFactory {
    static getPreprocessor(broker) {
        // Map broker names to their preprocessors
        const preprocessorMap = {
            [BROKERS.ABN_AMRO]: AbnAmroCsvPreprocessor
        };

        const normalizedBroker = Object.keys(preprocessorMap)
            .find(key => key.toLowerCase() === broker.toLowerCase());

        if (!normalizedBroker) {
            return null;
        }

        const PreprocessorClass = preprocessorMap[normalizedBroker];
        return new PreprocessorClass();
    }
}

export default CsvPreprocessorFactory;
