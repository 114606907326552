import { BaseConverter } from '../../../core/BaseConverter';
import { determineSecurityType, determineTransactionAction, determineExchangeRateAndCurrency } from '../../../utils/determiners';
import { formatAmount, formatDate, formatTime, parseAmountValue } from '../../../utils/formatters';
import { parseDateTimeString } from '../../../utils/dateTimeParser';
import { validateCurrency } from '../../../utils/validators';

export class BUXTransactionConverter extends BaseConverter {
  constructor(csvContent, selectedBroker, options = {}) {
    const buxOptions = {
      dateTimeField: 'Transaction Time (CET)',
      categoryField: 'Transaction Category',
      typeField: 'Transaction Type',
      ...options
    };
    super(csvContent, selectedBroker, buxOptions);
  }

  filterData(data) {
    return data.filter(row => {
      const category = (row[this.options.categoryField] || '').toLowerCase();
      const type = (row[this.options.typeField] || '').toLowerCase();
      
      return category === 'trades' && 
             (type === 'buy trade' || type === 'sell trade');
    });
  }

  transformData(data) {
    return data.map(row => {
      const dateTime = parseDateTimeString(row[this.options.dateTimeField]);
      const securityType = determineSecurityType(row['Asset Name'], row['Asset Id']);
      const isBuy = (row[this.options.typeField] || '').toLowerCase().startsWith('buy');
      const quantity = parseAmountValue(row['Trade Quantity'] || '0');
      const price = parseAmountValue(row['Trade Price'] || '0');
      const exchangeRate = row['Exchange Rate'] || '';
      const assetId = row['Asset Id'] || '';
      const assetName = row['Asset Name'] || '';
      const assetCurrency = row['Asset Currency'] || '';

      // Validate asset ID
      const validateAssetId = this.rules.search?.validation || ((value, context) => true);
      const isValidAssetId = validateAssetId(assetId, { type: securityType });

      // Determine exchange rate and currency
      const { rate: formattedExchangeRate, currency: exchangeRateCurrency } = 
        determineExchangeRateAndCurrency(assetCurrency, 'EUR', exchangeRate);

      return {
        broker: this.selectedBroker,
        name: assetName,
        type: determineSecurityType(assetName, assetId),
        search: isValidAssetId ? assetId : '',
        exchange: '',
        date: formatDate(dateTime),
        time: formatTime(dateTime),
        'transaction-action': determineTransactionAction(isBuy),
        'transaction-amount': formatAmount(quantity),
        'transaction-price': formatAmount(price),
        'transaction-price-currency': validateCurrency(assetCurrency) ? assetCurrency : '',
        'transaction-price-exchange-rate': formatAmount(formattedExchangeRate),
        'transaction-price-exchange-rate-currency': exchangeRateCurrency,
        'transaction-costs': '',
        'transaction-costs-currency': '',
        'transaction-costs-exchange-rate': '',
        'transaction-costs-exchange-rate-currency': '',
        'transaction-tax': '',
        'transaction-tax-currency': '',
        'transaction-tax-exchange-rate': '',
        'transaction-tax-exchange-rate-currency': ''
      };
    });
  }
}
