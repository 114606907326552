import { BaseConverter } from '../../../core/BaseConverter';
import { determineSecurityType, determineExchangeRateAndCurrency } from '../../../utils/determiners';
import { formatAmount, formatDate, formatTime, parseAmountValue } from '../../../utils/formatters';
import { validateCurrency } from '../../../utils/validators';
import { mapExchange } from '../../../config/exchangeMapping';
import { COLUMN_RULES } from '../../../config/columnRules';
import { parseDateTimeString } from '../../../utils/dateTimeParser';

export class DEGIRODividendConverter extends BaseConverter {
  constructor(csvContent, selectedBroker, options = {}) {
    const degiroOptions = {
      dateField: 'Datum',
      timeField: 'Tijd',
      ...options
    };
    super(csvContent, selectedBroker, degiroOptions);
  }

  filterData(data) {
    // Skip header row and filter dividend entries
    return data.slice(1).filter(row => {
      return row && 
             row.Datum && // Date
             row.Product && // Name
             row.ISIN && // ISIN
             row.Beurs && // Exchange
             row.Omschrijving && // Description
             row.Omschrijving.toLowerCase().includes('dividend') &&
             row.Totaal && // Amount
             !isNaN(parseFloat(String(row.Totaal).replace(',', '.')));
    });
  }

  transformData(data) {
    return data.map(row => {
      // Combine date and time fields
      const dateTime = parseDateTimeString(`${row[this.options.dateField]} ${row[this.options.timeField]}`);

      // Extract and validate amount
      const amount = parseAmountValue(row.Totaal || '0');
      const tax = parseAmountValue(row['Bronbelasting'] || '0');
      const assetCurrency = row._4 || 'EUR';
      const exchangeRate = row.Wisselkoers || '';

      // Determine exchange rate and currency
      const { rate: formattedExchangeRate, currency: exchangeRateCurrency } = 
        determineExchangeRateAndCurrency(assetCurrency, 'EUR', exchangeRate);

      const output = {
        broker: this.selectedBroker,
        name: row.Product || '',
        type: determineSecurityType(row.Product || '', row.ISIN || ''),
        search: row.ISIN || '',
        exchange: mapExchange(row.Beurs || ''),
        date: formatDate(dateTime),
        time: formatTime(dateTime),
        'dividend-type': 'Cash',
        'dividend-amount': formatAmount(amount),
        'dividend-amount-currency': validateCurrency(assetCurrency) ? assetCurrency : '',
        'dividend-amount-exchange-rate': formatAmount(formattedExchangeRate),
        'dividend-amount-exchange-rate-currency': exchangeRateCurrency,
        'dividend-tax': tax ? formatAmount(tax) : '',
        'dividend-tax-currency': tax ? (validateCurrency(assetCurrency) ? assetCurrency : '') : '',
        'dividend-tax-exchange-rate': '',
        'dividend-tax-exchange-rate-currency': ''
      };

      // Validate output against column rules
      const rules = { ...COLUMN_RULES.common, ...COLUMN_RULES.dividend };
      for (const [field, rule] of Object.entries(rules)) {
        if (rule.validation && !rule.validation(output[field], output)) {
          return null;
        }
      }

      return output;
    }).filter(row => row !== null);
  }
}
