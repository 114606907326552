import { parseDateTimeString } from './dateTimeParser';

/**
* Formats a number preserving all decimal places but ensuring at least 2
 * By default returns a positive amount with comma as decimal separator
 * @param {number|string} amount - The amount to format
 * @param {boolean} [allowNegative=false] - If true, allows negative numbers instead of converting to positive
 * @returns {string} Formatted amount string (e.g., "123,00", "-123,456", "123,40")
 */
export const formatAmount = (amount, allowNegative = false) => {
  // Handle null, undefined, empty string
  if (amount === null || amount === undefined || amount === '') return '';

  // Convert to number if string, handling comma decimals
  const num = typeof amount === 'string' ? parseFloat(amount.replace(',', '.')) : amount;
  
  // Return '0,00' only for actual zero values
  if (num === 0) return '0,00';
  
  // Get string representation of value, using abs only if not allowing negative
  const str = (allowNegative ? num : Math.abs(num)).toString();
  
  // Split into integer and decimal parts
  const [integer, decimal = ''] = str.split('.');
  
  // If no decimals or less than 2 decimals, pad with zeros to ensure at least 2
  const formattedDecimal = decimal.length < 2 ? decimal.padEnd(2, '0') : decimal;
  
  // Combine and replace dot with comma
  return `${integer},${formattedDecimal}`;
};

/**
 * Parses a string amount to a number
 * @param {string} amount - The amount string to parse
 * @returns {number} Parsed amount
 */
export const parseAmount = (amount) => {
  if (!amount) return 0;
  return parseFloat(amount.replace(',', '.'));
};

/**
 * Parse a string value to a float, defaulting to 0 if the value is invalid or empty
 * @param {string} value - The value to parse
 * @returns {number} The parsed float value
 */
export function parseAmountValue(value) {
  if (!value) return 0;
  // Replace comma with dot for proper float parsing
  return parseFloat(value.toString().replace(',', '.'));
}

/**
 * Calculate transaction costs by subtracting trade value from total amount
 * @param {number} totalAmount - The total transaction amount
 * @param {number} tradeValue - The trade value
 * @returns {number} The calculated costs rounded to 2 decimal places
 */
export function calculateTransactionCosts(totalAmount, tradeValue) {
  const costs = totalAmount - tradeValue;
  return Math.round(costs * 100) / 100; // Round to 2 decimal places
}

/**
 * Formats a date to DD-MM-YYYY format
 * @param {string|Date} date - Date to format
 * @returns {string} Formatted date string
 */
export const formatDate = (date) => {
  if (!date) return '';
  
  try {
    // If it's already a Date object, use it directly
    const dateObj = date instanceof Date ? date : parseDateTimeString(date);
    
    // Format to DD-MM-YYYY
    const day = String(dateObj.getDate()).padStart(2, '0');
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const year = dateObj.getFullYear();
    
    return `${day}-${month}-${year}`;
  } catch (error) {
    console.error('Error formatting date:', error);
    return '';
  }
};

/**
 * Formats time to HH:MM:SS format
 * @param {string|Date|null|undefined} time - Time to format
 * @param {string} defaultTime - Default time to use if no time provided (default: '00:00:00')
 * @returns {string} Formatted time string
 */
export const formatTime = (time, defaultTime = '00:00:00') => {
  if (!time) return defaultTime;
  
  let d;
  
  if (time instanceof Date) {
    d = time;
  } else if (typeof time === 'string') {
    // If the time is already in HH:MM:SS format, return it
    if (time.match(/^\d{2}:\d{2}:\d{2}$/)) {
      return time;
    }
    // Handle YYYYMMDD;HHMMSS format (Interactive Brokers format)
    if (time.match(/^\d{8};\d{6}$/)) {
      const [, timePart] = time.split(';');
      const hours = timePart.substring(0, 2);
      const minutes = timePart.substring(2, 4);
      const seconds = timePart.substring(4, 6);
      return `${hours}:${minutes}:${seconds}`;
    }
    // Handle YYYY-MM-DD HH:mm:ss format (BUX format)
    if (time.match(/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/)) {
      const [, timePart] = time.split(' ');
      return timePart;
    }
    // Handle HH:MM or HH:MM:SS format
    else if (time.includes(':')) {
      const parts = time.split(':').map(num => parseInt(num, 10));
      d = new Date();
      d.setHours(parts[0]);
      d.setMinutes(parts[1]);
      d.setSeconds(parts[2] || 0);
    }
    // Try standard date parsing as fallback
    else {
      d = new Date(time);
    }
  } else {
    return defaultTime;
  }
  
  // Check if time is valid
  if (isNaN(d.getTime())) {
    return defaultTime;
  }
  
  const hours = d.getHours().toString().padStart(2, '0');
  const minutes = d.getMinutes().toString().padStart(2, '0');
  const seconds = d.getSeconds().toString().padStart(2, '0');
  
  return `${hours}:${minutes}:${seconds}`;
};

/**
 * Flips an exchange rate (e.g., 0,96 becomes 1,04)
 * @param {string|number} rate - The exchange rate to flip
 * @returns {string} The flipped rate formatted with comma as decimal separator
 */
export function flipExchangeRate(rate) {
  if (!rate) return '';
  
  // Parse the rate to a number, handling comma decimals
  const num = typeof rate === 'string' ? parseFloat(rate.replace(',', '.')) : rate;
  
  // Return empty string for invalid numbers or zero
  if (isNaN(num) || num === 0) return '';
  
  // Calculate the flipped rate (1/rate) and limit to 6 decimals
  const flippedRate = Number((1 / num).toFixed(6));
  
  // Format with comma as decimal separator
  return formatAmount(flippedRate);
};
