import { useState, useEffect } from 'react';
import './App.scss';
// Import the broker registry
import { getConverter, CONVERTER_TYPE_VALUES, BROKER_VALUES, BROKER_DISPLAY_NAMES } from './core/BrokerRegistry';
// Validators
import { SkippedRowsConverter } from './core/SkippedRowsConverter';
import Papa from 'papaparse';
import CsvPreprocessorFactory from './core/csvPreprocessor/CsvPreprocessorFactory.js';

/**
 * -------------------------------------------------------------------------
 * Main application component that handles CSV file conversion for different brokers.
 * Manages state for:
 * - File upload and drag-and-drop functionality
 * - Conversion process and error handling
 * - Data display for different financial record types (transactions, dividends, etc.)
 * - User interface elements (modals, toasts, tabs)
 * - Broker selection with persistent storage
 * -------------------------------------------------------------------------
 */

function App() {
  // File handling states - Manages the upload process and drag-and-drop functionality
  const [file, setFile] = useState(null);
  const [isDragging, setIsDragging] = useState(false);

  // Conversion states - Tracks the conversion process and any errors that occur
  const [converting, setConverting] = useState(false);
  const [error, setError] = useState(null);

  // Data storage states - Holds the converted financial data for different record types
  const [convertedData, setConvertedData] = useState({
    transactions: null,
    dividends: null,
    bookings: null,
    expenses: null,
    skippedRows: null
  });

  // UI state management - Controls various UI elements like tabs, modals, and notifications
  const [activeTab, setActiveTab] = useState('transactions');
  const [showModal, setShowModal] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const [toast, setToast] = useState({ show: false, message: '', type: 'error' });

  // Broker selection state - Maintains the selected broker with localStorage persistence
  const [selectedBroker, setSelectedBroker] = useState(() => {
    const savedBroker = localStorage.getItem('selectedBroker');
    return savedBroker || BROKER_VALUES[0];
  });

  useEffect(() => {
    localStorage.setItem('selectedBroker', selectedBroker);
  }, [selectedBroker]);

  
  /**
   * -------------------------------------------------------------------------
   * File Handling Functions
   * These functions manage the drag-and-drop and file selection functionality
   * -------------------------------------------------------------------------
   */

  // Handles the dragover event for file drop zone
  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  // Handles the dragleave event for file drop zone
  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  // Processes a file drop event
  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleNewFile(e.dataTransfer.files[0]);
    }
  };

  // Handles file selection from input element
  const handleFileSelect = (e) => {
    if (e.target.files && e.target.files[0]) {
      handleNewFile(e.target.files[0]);
    }
  };

  // Resets states when a new file is selected
  const handleNewFile = (newFile) => {
    setFile(newFile);
    setError(null);
    setConvertedData({
      transactions: null,
      dividends: null,
      bookings: null,
      expenses: null,
      skippedRows: null
    });
    setCopySuccess(false);
  };

  /**
   * -------------------------------------------------------------------------
   * UI Helper Functions
   * Helper functions for clipboard operations, broker validation,
   * rendering of different data types and toast notifications
   * -------------------------------------------------------------------------
   */

  // Copy converted data to clipboard, excluding the header row
  const handleCopyToClipboard = async () => {
    try {
      const dataWithoutHeaders = convertedData[activeTab].split('\n').slice(1).join('\n');
      await navigator.clipboard.writeText(dataWithoutHeaders);
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
      setCopySuccess(false);
    }
  };

  // Validate if the selected broker is supported
  const isValidBroker = async (broker) => {
    return BROKER_VALUES.includes(broker);
  };

  // Get the appropriate title for the current data type
  const getTitle = () => {
    switch (activeTab) {
      case 'transactions':
        return 'Transactions';
      case 'dividends':
        return 'Dividends';
      case 'bookings':
        return 'Bookings';
      case 'expenses':
        return 'Expenses';
      case 'skippedRows':
        return 'Skipped Rows';
      default:
        return '';
    }
  };

  // Displays a toast notification
  const showToast = (message, type = 'error') => {
    setToast({ show: true, message, type });
    setTimeout(() => setToast({ show: false, message: '', type: 'error' }), 5000);
  };

  /**
   * -------------------------------------------------------------------------
   * Main conversion handler
   * Validates input, processes the CSV file, and converts data using appropriate converters
   * Handles preprocessing, parsing, and conversion for all supported record types
   * -------------------------------------------------------------------------
   */

  const handleConvert = async () => {
    // Input validation
    if (!file) {
      showToast('No file selected');
      return;
    }

    if (file.type !== 'text/csv' && !file.name.toLowerCase().endsWith('.csv')) {
      showToast('Please select a valid CSV file');
      return;
    }

    if (!selectedBroker) {
      showToast('Please select a broker');
      return;
    }

    // Validate broker
    try {
      const isValid = await isValidBroker(selectedBroker);
      if (!isValid) {
        throw new Error('Invalid broker selected');
      }
    } catch (error) {
      return;
    }
    
    // Reset states for new conversion
    setConverting(true);
    setError(null);
    setShowModal(false);
    setConvertedData({
      transactions: null,
      dividends: null,
      bookings: null,
      expenses: null,
      skippedRows: null
    });
    setCopySuccess(false);
    
    try {
      const fileContent = await file.text();
      
      // Attempt to preprocess the CSV data based on broker-specific requirements
      let processedContent = fileContent;
      const preprocessor = CsvPreprocessorFactory.getPreprocessor(selectedBroker);
      
      // If this broker has a custom preprocessor, apply it
      if (preprocessor) {
        try {
          processedContent = preprocessor.preprocess(fileContent);
          console.log('CSV preprocessed successfully');
        } catch (err) {
          console.warn('CSV preprocessing failed:', err);
        }
      }
      
      // Parse the processed CSV content
      const parsedData = Papa.parse(processedContent, { header: true, skipEmptyLines: true }).data;
      console.log('Total rows in CSV:', parsedData.length);

      // Initialize arrays to store conversion results and processed row tracking
      const results = [];
      const newData = {};
      const processedRows = [];

      // Convert data for each supported broker
      for (const type of CONVERTER_TYPE_VALUES) {
        try {
          // Get the appropriate converter for this broker and data typ
          const converterInstance = getConverter(selectedBroker, type, fileContent);
          if (!converterInstance) {
            throw new Error(`No converter found for ${selectedBroker} ${type}`);
          }

          // Convert the data and store results if successful
          const result = await converterInstance.convert();
          if (result) {
            results.push({ type, data: result });
            newData[type] = result;
            
            // Track which rows were successfully processed to identify skipped rows
            const filteredData = converterInstance.filterData(parsedData);
            if (Array.isArray(filteredData)) {
              processedRows.push(...filteredData);
              console.log(`Added ${filteredData.length} rows from ${type} converter`);
            }
          } else {
            newData[type] = null;
          }
        } catch (err) {
          console.error(`Failed to convert ${type}:`, err);
          newData[type] = null;
        }
      }

      // Process skipped rows which were not processed by other converters
      try {
        console.log('Total processed rows before skipped check:', processedRows.length);
        const skippedRowsConverter = new SkippedRowsConverter(fileContent, selectedBroker, processedRows);
        const skippedResult = await skippedRowsConverter.convert();
        console.log('Skipped rows result:', skippedResult ? skippedResult.split('\n').length - 1 : 0, 'rows');
        
        if (skippedResult && skippedResult.split('\n').length > 1) {
          newData.skippedRows = skippedResult;
          console.log('Added skipped rows to newData');
        }
      } catch (err) {
        console.error('Failed to process skipped rows:', err);
        newData.skippedRows = null;
      }

      if (Object.values(newData).every(v => v === null)) {
        throw new Error('No data could be converted from the file');
      }

      setConvertedData(newData);
      
      // Set active tab to first available conversion
      const firstAvailableTab = Object.keys(newData).find(key => newData[key]);
      if (firstAvailableTab) {
        setActiveTab(firstAvailableTab);
      }

      // Show modal with results
      setShowModal(true);
    } catch (error) {
      console.error('Conversion error:', error);
      setError(error.message);
      showToast(error.message, 'error');
    } finally {
      setConverting(false);
    }
  };

  /**
   * -------------------------------------------------------------------------
   * Other Functions
   * -------------------------------------------------------------------------
   */

  // Download the converted data as a CSV file
  const handleDownload = () => {
    // Split into rows and format as semicolon-separated CSV
    const rows = convertedData[activeTab].split('\n');
    const formattedRows = rows.map(row => row.split('\t').join(';')).join('\n');
    
    // Create and trigger download
    const blob = new Blob([formattedRows], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    
    link.setAttribute('href', url);
    link.setAttribute('download', `${selectedBroker}_${activeTab}.csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  // Render the appropriate data table based on the active tab
  const renderData = () => {
    if (!convertedData[activeTab]) {
      return null;
    }

    return (
      <div className="data-display">
        <div className="data-header">
          <h2>{getTitle()}</h2>
          <div className="data-actions">
            <button
              className="action-button"
              onClick={handleCopyToClipboard}
              title="Copy to clipboard"
            >
              {copySuccess ? 'Copied!' : 'Copy'}
            </button>
            <button
              className="action-button"
              onClick={handleDownload}
              title="Download as CSV"
            >
              Download
            </button>
          </div>
        </div>
        <pre className="data-content">{convertedData[activeTab]}</pre>
      </div>
    );
  };

  // Handle escape key press to close the modal
  const handleEscapeKey = (event) => {
    if (event.key === 'Escape') {
      setShowModal(false);
    }
  };

  // Handle tab selection for different data types and reset copy success
  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setCopySuccess(false);
  };

  // Render the modal with error details
  const renderModal = () => {
    if (!showModal) return null;

    return (
      <div className="modal-content">
        <h2>Error Details</h2>
        <pre>{error}</pre>
        <button onClick={() => setShowModal(false)}>Close</button>
      </div>
    );
  };

  // Update selected broker and persist the choice
  const handleBrokerChange = (e) => {
    setSelectedBroker(e.target.value);
  };

  useEffect(() => {
    if (showModal) {
      document.addEventListener('keydown', handleEscapeKey);
      return () => {
        document.removeEventListener('keydown', handleEscapeKey);
      };
    }
  }, [showModal]);

  return (
    <div className="min-h-screen py-6 flex flex-col justify-center sm:py-12">
      <div className="relative py-3 sm:max-w-xl sm:mx-auto">
        <div className="relative px-4 pt-5 pb-4 bg-white mx-8 md:mx-0 shadow-xl rounded-lg sm:p-6 border-card">
          <div className="beta-tag text-gray-600">Alpha test</div>
          <div className="max-w-md mx-auto">
            <div className="text-center">
              <h1 className="text-xl">Export converter</h1>
              <p className="mt-2 text-sm text-gray-600">Convert your broker exports to our standardized PDT format. More information can be found in our <a href="http://help-en.portfoliodividendtracker.com" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">manual</a>.</p>
            </div>

            <div className="mt-6">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Select Broker
              </label>
              <div className="relative">
                <select
                  className="block w-full pl-3 pr-10 py-2.5 text-base border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md bg-white shadow-sm appearance-none cursor-pointer"
                  value={selectedBroker}
                  onChange={handleBrokerChange}
                >
                  {BROKER_VALUES
                    .sort((a, b) => BROKER_DISPLAY_NAMES[a].localeCompare(BROKER_DISPLAY_NAMES[b]))
                    .map(broker => (
                    <option key={broker} value={broker}>
                      {BROKER_DISPLAY_NAMES[broker]}
                    </option>
                  ))}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" stroke="currentColor">
                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                </div>
              </div>
            </div>

            <div className="mt-6">
              <div
                className={`relative border-2 border-dashed rounded-lg p-6 ${
                  isDragging ? 'border-blue-500 bg-blue-50' : 'border-gray-300 hover:border-gray-400'
                }`}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
              >
                <div className="space-y-1 text-center">
                  <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48">
                    <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  <div className="text-sm text-gray-600">
                    <label htmlFor="file-upload" className="relative cursor-pointer">
                      <span>{file ? file.name : 'Drop CSV file here or'}</span>
                      <span className="hover:text-gray-950"> browse</span>
                      <input
                        id="file-upload"
                        type="file"
                        className="sr-only"
                        accept=".csv"
                        onChange={handleFileSelect}
                      />
                    </label>
                  </div>
                  <p className="text-xs text-gray-500">CSV files only</p>
                </div>
              </div>
            </div>

            <div className="mt-6">
              <button
                onClick={handleConvert}
                disabled={converting || !file}
                className={`w-full inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white ${
                  converting || !file
                    ? 'bg-gray-400 cursor-not-allowed'
                    : 'bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                }`}
              >
                {converting ? (
                  <>
                    <svg className="animate-spin -ml-1 mr-3 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" fill="currentColor"></circle>
                      <path className="opacity-75" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Converting...
                  </>
                ) : (
                  'Convert'
                )}
              </button>
            </div>

            {error && (
              <div className="mt-4 rounded-md bg-red-50 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                    </svg>
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-red-800">{error}</p>
                  </div>
                </div>
              </div>
            )}

            {toast.show && (
              <div className={`fixed bottom-4 right-4 rounded-md p-4 shadow-lg ${
                toast.type === 'error' ? 'bg-red-500' : 'bg-green-500'
              } text-white`}>
                <p className="text-sm font-medium">{toast.message}</p>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Modal */}
      {showModal && (
        <div 
          className="fixed z-10 inset-0 overflow-y-auto"
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-950 opacity-30"></div>
            </div>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6 border-card relative">
              <button
                onClick={() => setShowModal(false)}
                className="absolute top-4 right-4 text-gray-400 hover:text-gray-500 focus:outline-none"
              >
                <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
              <div>
                <div className="text-center">
                  <h3 className="text-xl flex items-center justify-center">
                    Conversion successful
                  </h3>
                  <p className="mt-2 text-sm text-gray-600">
                    Copy the converted data below and paste it to our <a href="https://help-en.portfoliodividendtracker.com/article/141-synchronize-actions-via-google-sheets" className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Template Sheet</a>.
                    {convertedData.skippedRows && convertedData.skippedRows.length > 0 && (
                      <span className="block mt-1 flex items-center justify-center text-orange-600 font-medium">
                        <svg className="h-5 w-5 text-orange-500 mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                          <path fillRule="evenodd" d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z" clipRule="evenodd" />
                        </svg>
                        Some rows couldn't be convertered or are skipped on purpose. They may need some attention.
                      </span>
                    )}
                  </p>
                </div>

                <div className="mt-4">
                  <div className="border-b border-gray-200">
                    <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                      {Object.entries(convertedData).map(([type, data]) =>
                        data ? (
                          <button
                            key={type}
                            onClick={() => handleTabClick(type)}
                            className={`${
                              activeTab === type
                                ? 'border-blue-600 text-blue-600'
                                : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                            } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm focus:outline-none`}
                          >
                            {type === 'skippedRows' ? 'Skipped Rows' : type.charAt(0).toUpperCase() + type.slice(1)}
                            <span className={`ml-2 py-0.5 px-2 rounded-full text-xs font-medium ${
                              activeTab === type ? 'bg-blue-100 text-blue-600' : 'bg-gray-100 text-gray-600'
                            }`}>
                              {data.split('\n').length - 1}
                            </span>
                          </button>
                        ) : null
                      )}
                    </nav>
                  </div>

                  <div className="mt-6">
                    <div className="bg-gray-50 rounded-lg overflow-hidden border border-gray-200">
                      {activeTab === 'skippedRows' ? (
                        <div className="overflow-x-auto max-h-96">
                          <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-100 sticky top-0 z-10">
                              <tr>
                                {convertedData[activeTab].split('\n')[0].split('\t').map((header, index) => (
                                  <th
                                    key={index}
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap bg-gray-100"
                                  >
                                    {header}
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                              {convertedData[activeTab]
                                .split('\n')
                                .slice(1) // Skip header row
                                .filter(row => row.trim()) // Skip empty rows
                                .map((row, rowIndex) => (
                                  <tr key={rowIndex} className={rowIndex % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                                    {row.split('\t').map((cell, cellIndex) => (
                                      <td
                                        key={cellIndex}
                                        className={`px-6 py-4 text-sm ${
                                          cellIndex === 0 ? '' : 'text-gray-900'
                                        } whitespace-nowrap`}
                                      >
                                        {cell}
                                      </td>
                                    ))}
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <pre className="p-4 overflow-x-auto max-h-96 text-sm text-gray-800">
                          {convertedData[activeTab]}
                        </pre>
                      )}
                    </div>
                  </div>

                  <div className="mt-6 flex justify-between items-center">
                    <a
                      href="http://help-en.portfoliodividendtracker.com/"
                      className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <svg className="h-5 w-5 mr-2 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
                      </svg>
                      Manual
                    </a>
                    <div className="flex space-x-3">
                      {activeTab !== 'skippedRows' && (
                        <button
                          onClick={handleCopyToClipboard}
                          className={`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white ${
                            copySuccess ? 'bg-green-600' : 'bg-blue-600 hover:bg-blue-700'
                          } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
                        >
                          {copySuccess ? (
                            <>
                              <svg className="h-5 w-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                              </svg>
                              Copied!
                            </>
                          ) : (
                            <>
                              <svg className="h-5 w-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3" />
                              </svg>
                              Copy to clipboard
                            </>
                          )}
                        </button>
                      )}
                      <button
                        onClick={handleDownload}
                        className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-blue-700 bg-blue-100 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      >
                        Download
                      </button>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
