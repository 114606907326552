import Papa from 'papaparse';
import { COLUMN_RULES } from '../config/columnRules'
import { sortByDateTime } from '../utils/sortUtils';
import CsvPreprocessorFactory from './csvPreprocessor/CsvPreprocessorFactory.js';
import { BROKERS } from './BrokerRegistry';

/**
 * Base converter class that provides common functionality for all broker converters.
 * All converters must output data in TSV (Tab Separated Values) format.
 */
export class BaseConverter {
  constructor(csvContent, broker, options = {}) {
    // Preprocess CSV content if a preprocessor exists for this broker
    const preprocessor = CsvPreprocessorFactory.getPreprocessor(broker);
    this.csvContent = preprocessor ? preprocessor.preprocess(csvContent) : csvContent;
    
    this.broker = broker;
    this.selectedBroker = BROKERS[broker] || broker;
    this.options = options;
    
    // Set validation rules based on converter type
    this.rules = { ...COLUMN_RULES.common }; // Always include common rules
    
    // Get converter type from options
    this.converterType = options.type;
    if (!this.converterType) {
      throw new Error('Converter type must be specified in options');
    }
    
    // Add specific rules based on converter type
    switch(this.converterType) {
      case 'transactions':
        Object.assign(this.rules, COLUMN_RULES.transaction);
        break;
      case 'dividends':
        Object.assign(this.rules, COLUMN_RULES.dividend);
        break;
      case 'bookings':
        Object.assign(this.rules, COLUMN_RULES.booking);
        break;
      case 'expenses':
        Object.assign(this.rules, COLUMN_RULES.expense);
        break;
      case 'skippedRows':
        // No additional rules needed for skipped rows
        break;
      default:
        throw new Error(`Unknown converter type: ${this.converterType}`);
    }
  }

  async convert() {
    try {
      console.log('Starting conversion...');
      
      if (!this.validateInput()) {
        console.log('Input validation failed');
        return null;
      }
      console.log('Input validation passed');

      const parsedData = this.parseCSV();
      if (!parsedData?.length) {
        console.log('No data after CSV parsing');
        return null;
      }
      console.log(`Parsed ${parsedData.length} rows from CSV`);

      const filteredData = this.filterData(parsedData);
      if (!filteredData?.length) {
        console.log('No data after filtering');
        return null;
      }
      console.log(`Filtered to ${filteredData.length} rows`);

      console.log('Starting data transformation...');
      const transformedData = this.transformData(filteredData);
      if (!transformedData?.length) {
        console.log('No data after transformation');
        return null;
      }
      console.log(`Transformed ${transformedData.length} rows`);

      // Sort the data by date and time in ascending order
      const sortedData = sortByDateTime(transformedData);
      console.log('Data sorted by date and time');

      const output = this.formatOutput(sortedData);
      console.log('Conversion completed successfully');
      return output;
    } catch (error) {
      console.error('Conversion error:', error.message);
      console.error('Error stack:', error.stack);
      return null;
    }
  }

  validateInput() {
    return this.csvContent && this.broker;
  }

  parseCSV() {
    const parseResult = Papa.parse(this.csvContent, {
      header: true,
      skipEmptyLines: true,
      transformHeader: header => header.trim(),
      transform: value => value?.trim() || ''
    });

    return parseResult.data;
  }

  // To be implemented by specific converters
  filterData(data) {
    throw new Error('filterData must be implemented by subclass');
  }

  // To be implemented by specific converters
  transformData(data) {
    throw new Error('transformData must be implemented by subclass');
  }

  /**
   * Default sort implementation that can be overridden by child classes
   */
  sortData(data) {
    // If no sorting is needed, return the data as is
    if (!data || !Array.isArray(data)) return data;
    
    // Try to sort by date if possible
    const dateField = Object.keys(data[0] || {}).find(key => 
      key.toLowerCase().includes('date') || key.toLowerCase().includes('time')
    );

    if (dateField) {
      return data.sort((a, b) => {
        const dateA = a[dateField] || '';
        const dateB = b[dateField] || '';
        
        if (!dateA && !dateB) return 0;
        if (!dateA) return 1;
        if (!dateB) return -1;
        
        return new Date(dateA) - new Date(dateB);
      });
    }

    return data;
  }

  formatTSV(headers, data) {
    return Papa.unparse({
      fields: headers,
      data: data
    }, {
      delimiter: '\t'
    });
  }

  formatOutput(data) {
    let headers;
    
    switch(this.converterType) {
      case 'transactions':
        headers = [
          'broker', 'name', 'type', 'search', 'exchange', 'date', 'time',
          'transaction-action', 'transaction-amount', 'transaction-price',
          'transaction-price-currency', 'transaction-price-exchange-rate',
          'transaction-price-exchange-rate-currency', 'transaction-costs',
          'transaction-costs-currency', 'transaction-costs-exchange-rate',
          'transaction-costs-exchange-rate-currency', 'transaction-tax',
          'transaction-tax-currency', 'transaction-tax-exchange-rate',
          'transaction-tax-exchange-rate-currency'
        ];
        break;
      case 'dividends':
        headers = [
        'broker', 'name', 'type', 'search', 'exchange', 'date', 'time',
        'dividend-amount', 'dividend-amount-currency', 'dividend-amount-exchange-rate',
        'dividend-amount-exchange-rate-currency', 
        'dividend-tax', 'dividend-tax-currency', 'dividend-tax-exchange-rate', 'dividend-tax-exchange-rate-currency'
        // dividend-type & dividend-costs is new here, which is only possible for the new version
        //,'dividend-type', 'dividend-costs', 'dividend-costs-currency', 'dividend-costs-exchange-rate', 'dividend-costs-exchange-rate-currency'
        ];
        break;
      case 'expenses':
        headers = [
          'broker', 'date', 'time', 'description',
          'expense-amount', 'expense-amount-currency',
          'expense-amount-exchange-rate', 'expense-amount-exchange-rate-currency'
        ];
        break;
      case 'bookings':
        headers = [
          'broker', 'date', 'time', 'booking-action',
          'booking-amount', 'booking-amount-currency'
        ];
        break;
      case 'skippedRows':
        // For skipped rows, we want to show all original columns
        headers = Object.keys(data[0] || {});
        break;
      default:
        throw new Error(`Unknown converter type: ${this.converterType}`);
    }

    return this.formatTSV(headers, data.map(row => {
      // Filter headers based on show conditions in columnRules
      const visibleHeaders = headers.filter(header => {
        const rule = this.rules[header];
        return !rule?.show || rule.show(row);
      });
      
      // Only include values for visible headers
      return visibleHeaders.map(header => row[header] || '');
    }));
  }
}
