import { BaseConverter } from '../../../core/BaseConverter';
import { determineBookingAction } from '../../../utils/determiners';
import { formatAmount, formatDate, formatTime, parseAmountValue } from '../../../utils/formatters';
import { parseDateTimeString } from '../../../utils/dateTimeParser';
import { validateCurrency } from '../../../utils/validators';

export class BUXBookingConverter extends BaseConverter {
  constructor(csvContent, selectedBroker, options = {}) {
    const buxOptions = {
      dateTimeField: 'Transaction Time (CET)',
      categoryField: 'Transaction Category',
      typeField: 'Transaction Type',
      ...options
    };
    super(csvContent, selectedBroker, buxOptions);
  }

  filterData(data) {
    return data.filter(row => {
      const category = (row[this.options.categoryField] || '').toLowerCase();
      const type = (row[this.options.typeField] || '').toLowerCase();

      return (
        ['deposits', 'withdrawals'].includes(category) ||
        (category === 'cash movements' && 
         (type.includes('conversion') || type.includes('transfer')))
      );
    });
  }

  transformData(data) {
    return data.map(row => {
      const dateTime = parseDateTimeString(row[this.options.dateTimeField]);
      const amount = parseAmountValue(row['Transaction Amount'] || '0');
      const exchangeRate = row['Exchange Rate'] || '';
      const currency = row['Transaction Currency'] || '';
      const type = row[this.options.typeField] || '';
      const category = row[this.options.categoryField] || '';

      return {
        broker: this.selectedBroker,
        date: formatDate(dateTime),
        time: formatTime(dateTime),
        'booking-action': determineBookingAction(type, amount),
        'booking-amount': formatAmount(amount),
        'booking-amount-currency': validateCurrency(currency) ? currency : '',
        'exchange-rate': exchangeRate
      };
    });
  }
}
