import { BaseConverter } from './BaseConverter';

/**
 * Converter that captures rows that were not processed by other converters
 * This helps users understand which data was not included in the conversion
 */
export class SkippedRowsConverter extends BaseConverter {
  constructor(csvContent, selectedBroker, processedRows) {
    super(csvContent, selectedBroker, { type: 'skippedRows' });
    this.processedRows = processedRows;
  }

  /**
   * Override validation to always return true since we want to show all skipped rows
   */
  validateInput() {
    return true;
  }

  /**
   * Override validation to always return true for all rows
   */
  validateRow() {
    return true;
  }

  /**
   * Filter to only include rows that weren't processed by other converters
   */
  filterData(data) {
    if (!Array.isArray(data) || !Array.isArray(this.processedRows)) {
      console.log('Invalid data or processedRows array');
      return [];
    }

    // Create a Set of processed row identifiers for efficient lookup
    const processedRowIds = new Set(
      this.processedRows.map(row => this.createRowIdentifier(row))
    );

    console.log(`Total rows: ${data.length}, Processed rows: ${processedRowIds.size}`);

    return data.filter(row => {
      const rowId = this.createRowIdentifier(row);
      return !processedRowIds.has(rowId);
    });
  }

  /**
   * Create a unique identifier for a row based on its contents
   */
  createRowIdentifier(row) {
    // Create a string of all values in the row
    return Object.values(row)
      .filter(value => value !== undefined && value !== null && value !== '')
      .join('|');
  }

  /**
   * Transform data by keeping original format but adding skip reason
   */
  transformData(data) {
    return data.map(row => {
      // Create a more readable version of the row
      const transformed = {};
      
      // Include all original fields
      Object.entries(row).forEach(([key, value]) => {
        transformed[key] = value || '';
      });

      return transformed;
    });
  }

  /**
   * Format output to show all fields in a readable format
   */
  formatOutput(data) {
    if (!data || data.length === 0) {
      return '';
    }

    // Get all unique headers from the data, excluding Skip Reason as it will be added first
    const headers = [...Array.from(
      new Set(data.flatMap(row => Object.keys(row)))
    )];

    return this.formatTSV(headers, data.map(row => 
      headers.map(header => row[header] || '')
    ));
  }
}
