import { BaseConverter } from '../../../core/BaseConverter';
import { determineBookingAction } from '../../../utils/determiners';
import { formatAmount, formatDate, formatTime, parseAmountValue } from '../../../utils/formatters';
import { validateCurrency } from '../../../utils/validators';

export class Trading212BookingConverter extends BaseConverter {
  constructor(csvContent, selectedBroker, options = {}) {
    const defaultOptions = {
      dateField: 'Time',
      timeField: 'Time',
      typeField: 'Action',
      ...options
    };
    super(csvContent, selectedBroker, defaultOptions);
  }

  filterData(data) {
    if (!Array.isArray(data)) {
      return [];
    }

    return data.filter(row => {
      if (!row[this.options.dateField]) {
        return false;
      }

      const action = (row[this.options.typeField] || '').toLowerCase();
      return action.includes('deposit') || action.includes('withdrawal');
    });
  }

  transformData(data) {
    return data.map(row => {
      const amount = parseAmountValue(row['Total'] || '0');
      if (amount === 0) return null;

      // Parse date and time
      const dateTime = row[this.options.dateField] || '';
      const [datePart, timePart] = dateTime.split(' ');
      const time = timePart ? timePart.split('.')[0] : '00:00';

      const action = (row[this.options.typeField] || '').toLowerCase();
      const currency = row['Currency (Total)'] || '';

      return {
        broker: this.broker,
        date: formatDate(datePart),
        time: formatTime(time),
        'booking-action': determineBookingAction(action),
        'booking-amount': formatAmount(amount),
        'booking-amount-currency': currency ? validateCurrency(currency) : '',
      };
    }).filter(Boolean);
  }
}
