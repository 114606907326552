import { BaseConverter } from '../../../core/BaseConverter';
import { determineBookingAction } from '../../../utils/determiners';
import { formatAmount, formatDate, formatTime, parseAmountValue } from '../../../utils/formatters';
import { validateCurrency } from '../../../utils/validators';

export class EviVanLanschotBookingConverter extends BaseConverter {
  constructor(csvContent, selectedBroker, options = {}) {
    const defaultOptions = {
      dateFields: ['Transactiedatum', 'Boekdatum'],
      typeField: 'Transactietype',
      delimiter: ';',
      type: 'bookings'
    };
    super(csvContent, selectedBroker, { ...defaultOptions, ...options });
  }

  getDateValue(row) {
    for (const field of this.options.dateFields) {
      if (row[field]) {
        return row[field];
      }
    }
    return null;
  }

  filterData(data) {
    return data.filter(row => {
      const date = this.getDateValue(row);
      if (!date) {
        return false;
      }

      const type = row[this.options.typeField]?.toLowerCase() || '';
      const accountType = row['Rekeningtype']?.toLowerCase() || '';
      const transactionType = row['Soort transactie']?.toLowerCase() || '';

      if (!type || !accountType || !transactionType) {
        return false;
      }

      // Accept both Zichtrekening and Effectenrekening
      const validAccountTypes = ['zichtrekening', 'effectenrekening'];
      const isValidAccountType = validAccountTypes.includes(accountType);

      return isValidAccountType && 
             transactionType === 'liquiditeiten' &&
             (type === 'cash boeking' || 
              type === 'geld boeking');
    });
  }

  transformData(data) {
    return data.map(row => {
      const type = row[this.options.typeField]?.toLowerCase() || '';
      const amount = parseAmountValue(row['Transactiebedrag'] || row['Bedrag'] || '');

      if (amount === 0) {
        return null;
      }

      const currency = type === 'cash boeking'
        ? row['Aantal / nominaal valuta'] || 'EUR'
        : row['Transactiebedrag valuta'] || 'EUR';
      
      // Use determineBookingAction helper which considers both amount and type
      const bookingAction = determineBookingAction(type, amount);

      return {
        broker: this.selectedBroker,
        date: formatDate(this.getDateValue(row)),
        time: formatTime('00:00'),
        'booking-action': bookingAction,
        'booking-amount': formatAmount(amount),
        'booking-amount-currency': validateCurrency(currency) ? currency : ''      };
    }).filter(Boolean);
  }
}
