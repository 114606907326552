// Interactive Brokers specific exchange mapping
export const IBKR_EXCHANGE_MAPPING = {
  'AEB': 'Euronext Amsterdam',
  'ALPHA': 'Alpha ATS',
  'AMEX': 'NYSE American',
  'ARCA': 'NYSE Markets',
  'ARCAEDGE': 'NYSE Markets',
  'ASX': 'Australia Exchange',
  'ATD': 'Automated Trade Desk',
  'BATECH': 'Bats',
  'BATEDE': 'Bats',
  'BATEEN': 'Bats',
  'BATEES': 'Bats',
  'BATEUK': 'Bats',
  'BATS': 'Bats',
  'BEX': 'Nasdaq',
  'BM': 'Bolsa de Madrid',
  'BONDESK': 'Bond Virtual Exchange',
  'BOX': 'USA Stocks',
  'BTRADE': 'USA Stocks',
  'BVME': 'Borsa Italiana',
  'BVME.ETF': 'Borsa Italiana',
  'BVL': 'Euronext Lisbon',
  'CBOE': 'USA Stocks',
  'CBOEB': 'USA Stocks',
  'CBSX': 'USA Stocks',
  'CDE': 'Toronto Exchange',
  'CFE': 'USA Stocks',
  'CHX': 'USA Stocks',
  'CHIX_CA': 'Toronto Exchange',
  'CHIXEN': 'London Stock Exchange',
  'CHIXES': 'London Stock Exchange',
  'CHIXUK': 'London Stock Exchange',
  'CME': 'USA Stocks',
  'CMEEOS': 'USA Stocks',
  'CPH': 'Copenhagen Exchange',
  'CSFBCROSS': 'USA Stocks',
  'DRCTEDGE': 'USA Stocks',
  'DRCTIOI': 'USA Stocks',
  'EBS': 'SIX Swiss Exchange',
  'ECBOT': 'USA Stocks',
  'EDGEA': 'USA Stocks',
  'ELX': 'USA Stocks',
  'ENEXT.BE': 'Euronext Brussels',
  'EUIBSI': 'Europe Fund Virtual Exchange',
  'EUSETSNL': 'Euronext Amsterdam',
  'FTA': 'Euronext Amsterdam',
  'FWB': 'Frankfurt Exchange',
  'GETTEX': 'Munich Exchange',
  'GLOBEX': 'USA Stocks',
  'HEX': 'Helsinki Exchange',
  'HKFE': 'Hong Kong Exchange',
  'IBBOND': 'Bond Virtual Exchange',
  'IBIS': 'XETRA Exchange',
  'IBIS2': 'XETRA Exchange',
  'ICAP': 'USA Stocks',
  'ICEUS': 'USA Stocks',
  'IDEM': 'Borsa Italiana',
  'IDEAL': 'FOREX',
  'IDEALCAD': 'Toronto Exchange',
  'IDEALFX': 'FOREX',
  'IDEALPRO': 'FOREX',
  'INET': 'Nasdaq',
  'IPE': 'USA Stocks',
  'ISE': 'USA Stocks',
  'ISED': 'Euronext Dublin',
  'IST': 'Istanbul Stock Exchange',
  'KSE': 'Korea Stock Exchange',
  'LAVA': 'USA Stocks',
  'LIFFE': 'London Stock Exchange',
  'LIFFE_NF': 'London Stock Exchange',
  'LJSE': 'Ljubljana Stock Exchange',
  'LME': 'London Stock Exchange',
  'LSE': 'London Stock Exchange',
  'LSEETF': 'London Stock Exchange',
  'LSSF': 'London Stock Exchange',
  'LUX': 'Luxembourg Stock Exchange',
  'MATIF': 'Euronext Paris',
  'MEFF': 'Bolsa de Madrid',
  'MEFFRV': 'Bolsa de Madrid',
  'MEXI': 'Mexican Exchange',
  'MEXDER': 'Mexican Exchange',
  'MNTRL': 'Toronto Exchange',
  'MONEP': 'Euronext Paris',
  'MUNICENTR': 'Bond Virtual Exchange',
  'MUNICENTM': 'Bond Virtual Exchange',
  'NASDAQ': 'Nasdaq',
  'NASDAQOM': 'Nasdaq',
  'NITE': 'OTC Markets',
  'NITEECN': 'USA Stocks',
  'NSE': 'NSE (India)',
  'NYSE': 'New York Stock Exchange',
  'NYBOT': 'USA Stocks',
  'NYFE': 'USA Stocks',
  'OMLX': 'Oslo Stock Exchange',
  'OMH': 'Helsinki Exchange',
  'OMXNO': 'Oslo Stock Exchange',
  'OMS': 'Stockholm Exchange',
  'OMSB': 'Stockholm Exchange',
  'OSE': 'Oslo Stock Exchange',
  'OSE.JPN': 'Tokyo Stock Exchange',
  'PHLX': 'USA Stocks',
  'PRA': 'Prague Stock Exchange',
  'PSE': 'Philippine Stock Exchange',
  'PSX': 'Nasdaq',
  'PURE': 'USA Stocks',
  'RFB': 'Riga Exchange',
  'SBF': 'Euronext Paris',
  'SEHK': 'Hong Kong Exchange',
  'SFB': 'Stockholm Exchange',
  'SGX': 'Singapore Exchange',
  'SWB': 'Stuttgart Exchange',
  'TASE': 'Tel Aviv Exchange',
  'TGATE': 'Stuttgart Exchange',
  'TIQSSWB': 'Stuttgart Exchange',
  'TRADEGATE': 'Stuttgart Exchange',
  'TRQXAT': 'Vienna Exchange',
  'TRQXCH': 'SIX Swiss Exchange',
  'TRQXDE': 'XETRA Exchange',
  'TRQXDK': 'Copenhagen Exchange',
  'TRQXEN': 'London Stock Exchange',
  'TSE': 'Toronto Exchange',
  'TSEJ': 'Tokyo Stock Exchange',
  'TSXV': 'Toronto Venture Exchange',
  'VALUE': 'USA Stocks',
  'VALUBOND': 'Bond Virtual Exchange',
  'VALUBONDG': 'Government Bonds',
  'VALUBONDM': 'Bond Virtual Exchange',
  'VIRTX': 'SIX Swiss Exchange',
  'VSE': 'Vienna Exchange',
  'VVPB': 'Vilnus Exchange',
  'WSE': 'Warsaw Stock Exchange',
  'XETIEN': 'XETRA Exchange',
  'XETIFI': 'XETRA Exchange',
  'XETIIT': 'XETRA Exchange',
  'XETIES': 'XETRA Exchange',
  'ZSE.HRV': 'Zagreb Stock Exchange'
};
