import { BaseConverter } from '../../../core/BaseConverter';
import { determineSecurityType, determineTransactionAction, determineExchangeRateAndCurrency } from '../../../utils/determiners';
import { formatAmount, formatDate, formatTime, parseAmountValue } from '../../../utils/formatters';
import { validateCurrency } from '../../../utils/validators';
import { mapExchange } from '../../../config/exchangeMapping';

export class Trading212TransactionConverter extends BaseConverter {
  constructor(csvContent, selectedBroker, options = {}) {
    const defaultOptions = {
      dateField: 'Time',
      timeField: 'Time',
      typeField: 'Action',
      nameField: 'Name',
      searchField: 'ISIN',
      currencyField: 'Currency (Total)',
      type: 'transactions',
      ...options
    };
    super(csvContent, selectedBroker, defaultOptions);
  }

  filterData(data) {
    if (!Array.isArray(data)) {
      return [];
    }

    return data.filter(row => {
      if (!row[this.options.dateField] || !row[this.options.nameField]) {
        return false;
      }

      const action = (row[this.options.typeField] || '').toLowerCase();
      return action.includes('market buy') || action.includes('market sell');
    });
  }

  transformData(data) {
    if (!Array.isArray(data)) {
      return [];
    }

    return data.map(row => {
      // Parse numeric values
      const amount = parseAmountValue(row['No. of shares'] || '0');
      const price = parseAmountValue(row['Price / share'] || '0');
      const exchangeRate = parseAmountValue(row['Exchange rate'] || '1');
      
      if (amount === 0) {
        return null;
      }

      // Parse date and time
      const dateTime = row[this.options.dateField] || '';
      const [datePart, timePart] = dateTime.split(' ');
      const time = timePart ? timePart.split('.')[0] : '00:00';

      // Determine action and type
      const isBuy = (row[this.options.typeField] || '').toLowerCase().includes('buy');
      const action = determineTransactionAction(isBuy);
      const securityType = determineSecurityType(row[this.options.nameField] || '', row[this.options.searchField] || '');

      // Handle currencies and exchange rates
      const priceCurrency = validateCurrency(row['Currency (Price / share)']);
      const totalCurrency = validateCurrency(row['Currency (Total)']);
      const { rate: priceExchangeRate, currency: priceExchangeRateCurrency } = determineExchangeRateAndCurrency(
        priceCurrency,
        totalCurrency,
        exchangeRate
      );

      // Handle fees
      const conversionFee = parseAmountValue(row['Currency conversion fee'] || '0');
      const feeCurrency = validateCurrency(row['Currency (Currency conversion fee)'] || totalCurrency);
      const { rate: feeExchangeRate, currency: feeExchangeRateCurrency } = determineExchangeRateAndCurrency(
        feeCurrency,
        totalCurrency,
        exchangeRate
      );

      // Handle French transaction tax
      const frenchTax = parseAmountValue(row['French transaction tax'] || '0');
      const frenchTaxCurrency = validateCurrency(row['Currency (French transaction tax)'] || totalCurrency);
      const { rate: taxExchangeRate, currency: taxExchangeRateCurrency } = determineExchangeRateAndCurrency(
        frenchTaxCurrency,
        totalCurrency,
        exchangeRate
      );

      return {
        broker: this.broker,
        date: formatDate(datePart),
        time: formatTime(time),
        search: row[this.options.searchField] || '',
        name: row[this.options.nameField] || '',
        type: securityType,
        exchange: '', // Trading 212 doesn't provide exchange info
        'transaction-action': action,
        'transaction-amount': formatAmount(amount),
        'transaction-price': formatAmount(price),
        'transaction-price-currency': priceCurrency ? validateCurrency(priceCurrency) : '',
        'transaction-price-exchange-rate': formatAmount(priceExchangeRate),
        'transaction-price-exchange-rate-currency': priceExchangeRateCurrency ? validateCurrency(priceExchangeRateCurrency) : '',
        'transaction-costs': conversionFee > 0 ? formatAmount(conversionFee) : '',
        'transaction-costs-currency': conversionFee > 0 ? (feeCurrency ? validateCurrency(feeCurrency) : '') : '',
        'transaction-costs-exchange-rate': conversionFee > 0 ? formatAmount(feeExchangeRate) : '',
        'transaction-costs-exchange-rate-currency': conversionFee > 0 ? (feeExchangeRateCurrency ? validateCurrency(feeExchangeRateCurrency) : '') : '',
        'transaction-tax': frenchTax > 0 ? formatAmount(frenchTax) : '',
        'transaction-tax-currency': frenchTax > 0 ? (frenchTaxCurrency ? validateCurrency(frenchTaxCurrency) : '') : '',
        'transaction-tax-exchange-rate': frenchTax > 0 ? formatAmount(taxExchangeRate) : '',
        'transaction-tax-exchange-rate-currency': frenchTax > 0 ? (taxExchangeRateCurrency ? validateCurrency(taxExchangeRateCurrency) : '') : ''
      };
    }).filter(Boolean);
  }
}
