/**
 * Parses a date/time string into a Date object
 * @param {string} dateTimeStr - The date/time string to parse (expected format: YYYY-MM-DD HH:mm:ss)
 * @returns {Date} Parsed date or throws error if invalid
 */
export const parseDateTimeString = (dateTimeStr) => {
  if (!dateTimeStr) {
    console.error('Empty date string received');
    throw new Error('Date/time string is required but was empty');
  }

  // First try direct parsing
  let date = new Date(dateTimeStr);
  if (!isNaN(date.getTime())) {
    return date;
  }

  // Try parsing different date formats
  const formats = [
    // YYYYMMDD (Interactive Brokers format without time)
    /^(\d{4})(\d{2})(\d{2})$/,
    // YYYYMMDD;HHMMSS (Interactive Brokers format)
    /^(\d{4})(\d{2})(\d{2});(\d{2})(\d{2})(\d{2})$/,
    // YYYY-MM-DD HH:mm:ss (with optional leading zeros)
    /^(\d{4})-(\d{1,2})-(\d{1,2})\s+(\d{1,2}):(\d{2}):(\d{2})$/,
    // DD-MM-YYYY HH:mm:ss (with optional leading zeros)
    /^(\d{1,2})-(\d{1,2})-(\d{4})\s+(\d{1,2}):(\d{2}):(\d{2})$/,
    // YYYY-MM-DD HH:mm (with optional leading zeros)
    /^(\d{4})-(\d{1,2})-(\d{1,2})\s+(\d{1,2}):(\d{2})$/,
    // DD-MM-YYYY HH:mm (with optional leading zeros)
    /^(\d{1,2})-(\d{1,2})-(\d{4})\s+(\d{1,2}):(\d{2})$/,
    // YYYY-MM-DD (date only)
    /^(\d{4})-(\d{1,2})-(\d{1,2})$/,
    // DD-MM-YYYY (date only)
    /^(\d{1,2})-(\d{1,2})-(\d{4})$/,
  ];

  for (const format of formats) {
    const match = dateTimeStr.match(format);
    if (match) {
      let [_, first, second, third, hours = '00', minutes = '00', seconds = '00'] = match;
      
      // Determine if it's YYYY-MM-DD or DD-MM-YYYY format
      let year, month, day;
      if (first.length === 4) {
        // YYYY-MM-DD format
        [year, month, day] = [first, second, third];
      } else {
        // DD-MM-YYYY format
        [day, month, year] = [first, second, third];
      }
      
      // Validate the components
      const h = parseInt(hours);
      const m = parseInt(minutes);
      const s = parseInt(seconds);
      const y = parseInt(year);
      const mo = parseInt(month);
      const d = parseInt(day);
      
      if (h < 0 || h > 23 || m < 0 || m > 59 || s < 0 || s > 59) {
        continue; // Invalid time components
      }
      
      if (mo < 1 || mo > 12 || d < 1 || d > 31) {
        continue; // Invalid date components
      }

      // Create date using UTC to avoid timezone issues
      date = new Date(Date.UTC(y, mo - 1, d, h, m, s));
      
      if (!isNaN(date.getTime())) {
        return date;
      }
    }
  }

  throw new Error(`Invalid date/time string: ${dateTimeStr}`);
};
