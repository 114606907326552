import { BaseConverter } from '../../../core/BaseConverter';
import { determineExchangeRateAndCurrency } from '../../../utils/determiners';
import { formatAmount, formatDate, formatTime, parseAmountValue } from '../../../utils/formatters';
import { parseDateTimeString } from '../../../utils/dateTimeParser';
import { validateCurrency } from '../../../utils/validators';

export class BUXExpenseConverter extends BaseConverter {
  constructor(csvContent, selectedBroker, options = {}) {
    const buxOptions = {
      dateTimeField: 'Transaction Time (CET)',
      categoryField: 'Transaction Category',
      typeField: 'Transaction Type',
      ...options
    };
    super(csvContent, selectedBroker, buxOptions);
  }

  filterData(data) {
    return data.filter(row => {
      const category = (row[this.options.categoryField] || '').toLowerCase();
      const type = (row[this.options.typeField] || '').toLowerCase();

      return (
        // Include fees and interest charges
        category === 'fees' ||
        (category === 'interest' && type.includes('charge')) ||
        // Include promotional items (they are in 'others' category)
        (category === 'others' && type === 'promotional') ||
        // Include any other expense-related categories
        type.includes('fee') || 
        type.includes('commission')
      );
    });
  }

  transformData(data) {
    return data.map(row => {
      const dateTime = parseDateTimeString(row[this.options.dateTimeField]);
      const amount = parseAmountValue(row['Transaction Amount'] || '0');
      const assetCurrency = row['Transaction Currency'] || '';
      const exchangeRate = row['Exchange Rate'] || '';

      // Determine exchange rate and currency
      const { rate: formattedExchangeRate, currency: exchangeRateCurrency } = 
        determineExchangeRateAndCurrency(assetCurrency, 'EUR', exchangeRate);

      const description = this.determineExpenseType(row[this.options.typeField]);

      return {
        broker: this.selectedBroker,
        date: formatDate(dateTime),
        time: formatTime(dateTime),
        description: description,
        'expense-amount': formatAmount(-1 * amount, { allowNegative: true }),
        'expense-amount-currency': validateCurrency(assetCurrency) ? assetCurrency : '',
        'expense-amount-exchange-rate': formattedExchangeRate,
        'expense-amount-exchange-rate-currency': exchangeRateCurrency
      };
    });
  }

  determineExpenseType(type) {
    type = (type || '').toLowerCase();

    if (type === 'promotional') return 'Promotional';
    if (type.includes('commission')) return 'Commission';
    if (type.includes('fee')) return 'Fee';
    return 'Other Expense';
  }
}
