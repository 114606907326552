import { BaseConverter } from '../../../core/BaseConverter';
import { determineSecurityType, determineTransactionAction, determineExchangeRateAndCurrency } from '../../../utils/determiners';
import { formatAmount, formatDate, formatTime, parseAmountValue, flipExchangeRate } from '../../../utils/formatters';
import { validateCurrency } from '../../../utils/validators';
import { mapExchange } from '../../../config/exchangeMapping';
import { IBKR_EXCHANGE_MAPPING } from '../config/IBExchangeMapping.js';

export class InteractiveBrokersTransactionConverter extends BaseConverter {
  constructor(csvContent, selectedBroker, options = {}) {
    const defaultOptions = {
      dateField: 'Date/Time',
      timeField: 'Date/Time',
      typeField: 'TransactionType',
      nameField: 'Symbol',
      searchField: 'SecurityID',
      currencyField: 'Currency',
      ...options
    };
    super(csvContent, selectedBroker, defaultOptions);
  }

  filterData(data) {
    if (!Array.isArray(data)) {
      return [];
    }

    return data.filter(row => {
      if (!row[this.options.dateField]) {
        return false;
      }

      const type = (row[this.options.typeField] || '').toLowerCase();
      const assetClass = row.AssetClass || '';

      // Skip rows with type not equal to 'exchtrade' or AssetClass equal to 'CASH'
      return type === 'exchtrade' && assetClass !== 'CASH';
    });
  }

  transformData(data) {
    return data.map(row => {
      const price = parseAmountValue(row.Price || '');
      const amount = parseAmountValue(row.Quantity || '');
      const commission = parseAmountValue(row.Commission || '');
      
      // Get currencies from different columns
      const priceCurrency = row.CurrencyPrimary || '';
      const costsCurrency = row.CommissionCurrency || '';

      if (amount === 0) return null;

      // Parse date and time from Date/Time column
      const dateTime = row[this.options.dateField] || '';
      const date = formatDate(dateTime);
      const time = formatTime(dateTime);

      // Try IBKR specific mapping first, then fall back to global mapping
      const exchange = IBKR_EXCHANGE_MAPPING[row.ListingExchange] || mapExchange(row.ListingExchange);
      const securityType = determineSecurityType(row[this.options.nameField] || '', row[this.options.searchField] || '');
      
      // Determine if it's a buy or sell based on the Buy/Sell column
      const isBuy = row['Buy/Sell'] === 'BUY';

      return {
        broker: this.selectedBroker,
        date: date,
        time: time,
        search: row[this.options.searchField],
        name: row.Description || row[this.options.nameField],
        type: securityType,
        exchange: exchange,
        'transaction-action': determineTransactionAction(isBuy),
        'transaction-amount': formatAmount(amount),
        'transaction-price': formatAmount(price),
        'transaction-price-currency': validateCurrency(priceCurrency),
        'transaction-price-exchange-rate': '',
        'transaction-price-exchange-rate-currency': '',
        'transaction-costs': formatAmount(commission),
        'transaction-costs-currency': validateCurrency(costsCurrency),
        'transaction-costs-exchange-rate': '',
        'transaction-costs-exchange-rate-currency': '',
        'transaction-tax': '',
        'transaction-tax-currency': '',
        'transaction-tax-exchange-rate': '',
        'transaction-tax-exchange-rate-currency': ''

      };
    }).filter(Boolean);
  }
}
