import { BaseConverter } from '../../../core/BaseConverter';
import { determineSecurityType, determineExchangeRateAndCurrency } from '../../../utils/determiners';
import { formatAmount, formatDate, formatTime, parseAmountValue, flipExchangeRate } from '../../../utils/formatters';
import { validateCurrency } from '../../../utils/validators';
import { mapExchange } from '../../../config/exchangeMapping';
import { IBKR_EXCHANGE_MAPPING } from '../config/IBExchangeMapping.js';
import { VALID_DIVIDEND_TYPES } from '../../../config/constants';

export class InteractiveBrokersDividendConverter extends BaseConverter {
  constructor(csvContent, selectedBroker, options = {}) {
    const defaultOptions = {
      dateField: 'Date/Time',
      timeField: 'Date/Time',
      typeField: 'Type',
      nameField: 'Symbol',
      searchField: 'ISIN',
      ...options
    };
    super(csvContent, selectedBroker, defaultOptions);
  }

  determineDividendType(description = '') {
    const desc = description.toLowerCase();
    if (desc.includes('stock dividend') || desc.includes('share dividend')) {
      return VALID_DIVIDEND_TYPES[1]; // Stock
    } else if (desc.includes('staking') || desc.includes('reward')) {
      return VALID_DIVIDEND_TYPES[1]; // Staking
    }
    return VALID_DIVIDEND_TYPES[0]; // Cash
  }

  createRowIdentifier(row) {
    // Create a unique identifier for a row based on its contents
    return Object.values(row)
      .filter(value => value !== undefined && value !== null && value !== '')
      .join('|');
  }

  filterData(data) {
    if (!Array.isArray(data)) return [];

    // Find all dividend entries
    this.processedRows = data.filter(row => 
      row[this.options.dateField] && 
      (row[this.options.typeField] || '').toLowerCase() === 'dividends'
    );

    // Find matching tax entries for these dividends
    const dividendActionIds = new Set(this.processedRows.map(row => row.ActionID));
    this.taxRows = data.filter(row => 
      row[this.options.dateField] && 
      (row[this.options.typeField] || '').toLowerCase() === 'withholding tax' &&
      dividendActionIds.has(row.ActionID)
    );

    // Return all processed rows to prevent them showing up as skipped
    return [...this.processedRows, ...this.taxRows];
  }

  transformData() {
    return this.processedRows.map(row => {
      const amount = parseAmountValue(row.Amount || '');
      if (amount === 0) return null;

      // Sum up all tax entries for this dividend, respecting positive/negative amounts
      const tax = this.taxRows
        .filter(tax => tax.ActionID === row.ActionID)
        .reduce((sum, entry) => sum + parseAmountValue(entry.Amount || ''), 0);

      // Get currency information
      const assetCurrency = row.CurrencyPrimary || row.Currency || '';
      const { rate: formattedExchangeRate, currency: exchangeRateCurrency } = 
        determineExchangeRateAndCurrency(assetCurrency, 'EUR', row.FXRateToBase || '');

      // Parse date and time
      const dateTime = row[this.options.dateField] || '';
      const date = formatDate(dateTime);
      const time = formatTime(dateTime);

      // Get security information
      const exchange = IBKR_EXCHANGE_MAPPING[row.ListingExchange] || mapExchange(row.ListingExchange);
      const securityType = determineSecurityType(row.AssetClass || '', row[this.options.searchField] || '');

      return {
        broker: this.selectedBroker,
        date,
        time,
        search: row.ISIN || '',
        name: row.Symbol || '',
        type: securityType,
        exchange,
        'dividend-type': this.determineDividendType(row.Description),
        'dividend-amount': formatAmount(amount, { allowNegative: true }),
        'dividend-amount-currency': validateCurrency(assetCurrency),
        'dividend-amount-exchange-rate': flipExchangeRate(formattedExchangeRate),
        'dividend-amount-exchange-rate-currency': exchangeRateCurrency,
        'dividend-costs': '',
        'dividend-costs-currency': '',
        'dividend-costs-exchange-rate': '',
        'dividend-costs-exchange-rate-currency': '',
        'dividend-tax': tax ? formatAmount(tax) : '',
        'dividend-tax-currency': tax ? validateCurrency(assetCurrency) : '',
        'dividend-tax-exchange-rate': tax ? flipExchangeRate(formattedExchangeRate) : '',
        'dividend-tax-exchange-rate-currency': tax ? exchangeRateCurrency : ''
      };
    }).filter(Boolean);
  }
}
