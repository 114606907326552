import { BaseConverter } from '../../../core/BaseConverter';
import { determineTransactionAction } from '../../../utils/determiners';
import { formatAmount, formatDate, formatTime, parseAmountValue, calculateTransactionCosts } from '../../../utils/formatters';
import { VALID_SECURITY_TYPES } from '../../../config/constants';
import { validateCurrency } from '../../../utils/validators';

export class AbnAmroTransactionConverter extends BaseConverter {
  constructor(csvContent, selectedBroker, options = {}) {
    const defaultOptions = {
      dateField: 'Datum',
      nameField: 'Naam',
      amountField: 'Aantal/Bedrag',
      priceField: 'Koers',
      typeField: 'Type',
      orderTypeField: 'Order type',
      netValueField: 'Netto waarde',
      type: 'transactions',
      ...options
    };
    super(csvContent, selectedBroker, defaultOptions);
  }

  filterData(data) {
    if (!Array.isArray(data)) {
      return [];
    }

    return data.filter(row => {
      if (!row[this.options.dateField] || !row[this.options.nameField]) {
        return false;
      }

      const orderType = (row[this.options.orderTypeField] || '').toLowerCase();
      
      // Include regular buy/sell transactions and cancellations
      return orderType === 'aankoop' || 
             orderType === 'verkoop' || 
             orderType === 'annulering';
    });
  }

  transformData(data) {
    return data.map(row => {
      const amount = parseAmountValue(row[this.options.amountField] || '');
      const price = parseAmountValue(row[this.options.priceField] || '');
      const netValue = parseAmountValue(row[this.options.netValueField] || '');
      
      if (amount === 0) return null;

      const orderType = (row[this.options.orderTypeField] || '').toLowerCase();
      const isBuy = orderType === 'aankoop' || 
                   (orderType === 'ca' && amount > 0);
      const isSell = orderType === 'verkoop' || 
                    orderType === 'annulering' ||
                    (orderType === 'ca' && amount < 0);

      // Skip if neither buy nor sell
      if (!isBuy && !isSell) return null;

      const currency = row.Currency || 'EUR';
      
      return {
        broker: this.selectedBroker,
        name: row[this.options.nameField] || '',
        type: VALID_SECURITY_TYPES[0],
        search: '',
        exchange: '',
        date: formatDate(row[this.options.dateField]),
        time: formatTime("00:00"),
        'transaction-action': determineTransactionAction(isBuy),
        'transaction-amount': formatAmount(amount),
        'transaction-price': formatAmount(price || netValue / amount),
        'transaction-price-currency': currency ? validateCurrency(currency) : '',
        'transaction-price-exchange-rate': '',
        'transaction-price-exchange-rate-currency': '',
        'transaction-costs': '',
        'transaction-costs-currency': '',
        'transaction-costs-exchange-rate': '',
        'transaction-costs-exchange-rate-currency': '',
        'transaction-tax': '',
        'transaction-tax-currency': '',
        'transaction-tax-exchange-rate': '',
        'transaction-tax-exchange-rate-currency': ''
      };
    }).filter(Boolean);
  }
}
