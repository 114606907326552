import { BaseConverter } from '../../../core/BaseConverter';
import { determineSecurityType, determineExchangeRateAndCurrency } from '../../../utils/determiners';
import { formatAmount, formatDate, formatTime, parseAmountValue } from '../../../utils/formatters';
import { mapExchange } from '../../../config/exchangeMapping';
import { validateCurrency } from '../../../utils/validators';
import { VALID_DIVIDEND_TYPES } from '../../../config/constants';

export class Trading212DividendConverter extends BaseConverter {
  constructor(csvContent, selectedBroker, options = {}) {
    const defaultOptions = {
      dateField: 'Time',
      timeField: 'Time',
      typeField: 'Action',
      nameField: 'Name',
      searchField: 'ISIN',
      ...options
    };
    super(csvContent, selectedBroker, defaultOptions);
  }

  /**
   * Determines the dividend type based on the transaction details
   * @param {Object} row The transaction row
   * @returns {string} One of the valid dividend types: 'Cash', 'Stock', or 'Staking'
   */
  determineDividendType(row) {
    const action = (row[this.options.typeField] || '').toLowerCase();
    const notes = (row['Notes'] || '').toLowerCase();
    
    if (notes.includes('stock dividend') || notes.includes('share dividend')) {
      return VALID_DIVIDEND_TYPES[1]; // Stock dividend
    } else if (notes.includes('staking') || notes.includes('reward')) {
      return VALID_DIVIDEND_TYPES[2]; // Staking reward
    } else {
      return VALID_DIVIDEND_TYPES[0]; // Default to Cash dividend
    }
  }

  filterData(data) {
    if (!Array.isArray(data)) {
      return [];
    }

    return data.filter(row => {
      if (!row[this.options.dateField] || !row[this.options.nameField]) {
        return false;
      }

      const action = (row[this.options.typeField] || '').toLowerCase();
      return action.includes('dividend');
    });
  }

  transformData(data) {
    return data.map(row => {
      const amount = parseAmountValue(row['Total'] || '0');
      const tax = parseAmountValue(row['Withholding tax'] || '0');
      const totalCurrency = validateCurrency(row['Currency (Total)'] || '');
      const exchangeRate = parseAmountValue(row['Exchange rate'] || '1');

      if (amount === 0) return null;

      // Parse date and time
      const dateTime = row[this.options.dateField] || '';
      const [datePart, timePart] = dateTime.split(' ');
      const time = timePart ? timePart.split('.')[0] : '00:00';

      // Handle currencies and exchange rates
      const { rate: dividendExchangeRate, currency: dividendExchangeRateCurrency } = determineExchangeRateAndCurrency(
        totalCurrency,
        totalCurrency,
        exchangeRate
      );

      // Determine security type
      const securityType = determineSecurityType(row[this.options.nameField] || '', row[this.options.searchField] || '');

      return {
        broker: this.broker,
        name: row[this.options.nameField] || '',
        search: row[this.options.searchField] || '',
        date: formatDate(datePart),
        time: formatTime(time),
        type: securityType,
        'dividend-type': this.determineDividendType(row),
        'dividend-amount': formatAmount(amount),
        'dividend-amount-currency': totalCurrency ? validateCurrency(totalCurrency) : '',
        'dividend-amount-exchange-rate': formatAmount(dividendExchangeRate),
        'dividend-amount-exchange-rate-currency': dividendExchangeRateCurrency ? validateCurrency(dividendExchangeRateCurrency) : '',
        'dividend-costs': '', // Trading212 doesn't have dividend costs
        'dividend-costs-currency': '',
        'dividend-costs-exchange-rate': '',
        'dividend-costs-exchange-rate-currency': '',
        'dividend-tax': tax ? formatAmount(tax) : '',
        'dividend-tax-currency': tax ? (totalCurrency ? validateCurrency(totalCurrency) : '') : '',
        'dividend-tax-exchange-rate': tax ? formatAmount(dividendExchangeRate) : '',
        'dividend-tax-exchange-rate-currency': tax ? (dividendExchangeRateCurrency ? validateCurrency(dividendExchangeRateCurrency) : '') : ''
      };
    }).filter(Boolean);
  }
}
