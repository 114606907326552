// Import all converters
import { DEGIROTransactionConverter } from '../brokers/degiro/converters/DEGIROTransactionConverter';
import { DEGIRODividendConverter } from '../brokers/degiro/converters/DEGIRODividendConverter';
import { DEGIROBookingConverter } from '../brokers/degiro/converters/DEGIROBookingConverter';
import { DEGIROExpenseConverter } from '../brokers/degiro/converters/DEGIROExpenseConverter';
import { BUXTransactionConverter } from '../brokers/bux/converters/BUXTransactionConverter';
import { BUXDividendConverter } from '../brokers/bux/converters/BUXDividendConverter';
import { BUXBookingConverter } from '../brokers/bux/converters/BUXBookingConverter';
import { BUXExpenseConverter } from '../brokers/bux/converters/BUXExpenseConverter';
import { BitvavoTransactionConverter } from '../brokers/bitvavo/converters/BitvavoTransactionConverter';
import { BitvavoDividendConverter } from '../brokers/bitvavo/converters/BitvavoDividendConverter';
import { BitvavoBookingConverter } from '../brokers/bitvavo/converters/BitvavoBookingConverter';
import { BitvavoExpenseConverter } from '../brokers/bitvavo/converters/BitvavoExpenseConverter';
import { AbnAmroTransactionConverter } from '../brokers/abn-amro/converters/AbnAmroTransactionConverter';
import { AbnAmroDividendConverter } from '../brokers/abn-amro/converters/AbnAmroDividendConverter';
import { AbnAmroBookingConverter } from '../brokers/abn-amro/converters/AbnAmroBookingConverter';
import { AbnAmroExpenseConverter } from '../brokers/abn-amro/converters/AbnAmroExpenseConverter';
import { EviVanLanschotTransactionConverter } from '../brokers/evi-van-lanschot/converters/EviVanLanschotTransactionConverter';
import { EviVanLanschotDividendConverter } from '../brokers/evi-van-lanschot/converters/EviVanLanschotDividendConverter';
import { EviVanLanschotBookingConverter } from '../brokers/evi-van-lanschot/converters/EviVanLanschotBookingConverter';
import { EviVanLanschotExpenseConverter } from '../brokers/evi-van-lanschot/converters/EviVanLanschotExpenseConverter';
import { InteractiveBrokersTransactionConverter } from '../brokers/interactive-brokers/converters/InteractiveBrokersTransactionConverter';
import { InteractiveBrokersDividendConverter } from '../brokers/interactive-brokers/converters/InteractiveBrokersDividendConverter';
import { InteractiveBrokersBookingConverter } from '../brokers/interactive-brokers/converters/InteractiveBrokersBookingConverter';
import { InteractiveBrokersExpenseConverter } from '../brokers/interactive-brokers/converters/InteractiveBrokersExpenseConverter';
import { Trading212TransactionConverter } from '../brokers/trading212/converters/Trading212TransactionConverter';
import { Trading212DividendConverter } from '../brokers/trading212/converters/Trading212DividendConverter';
import { Trading212BookingConverter } from '../brokers/trading212/converters/Trading212BookingConverter';
import { Trading212ExpenseConverter } from '../brokers/trading212/converters/Trading212ExpenseConverter';

// Define converter types
export const CONVERTER_TYPES = {
  TRANSACTION: 'transactions',
  DIVIDEND: 'dividends',
  BOOKING: 'bookings',
  EXPENSE: 'expenses'
};

export const CONVERTER_TYPE_VALUES = Object.values(CONVERTER_TYPES);

// Define brokers
export const BROKERS = {
  //DEGIRO: 'DEGIRO',
  //BUX: 'BUX',
  BITVAVO: 'Bitvavo',
  ABN_AMRO: 'ABN Amro',
  EVI_VAN_LANSCHOT: 'Evi van Lanschot',
  INTERACTIVE_BROKERS: 'Interactive Brokers',
  TRADING212: 'Trading 212'
};

// Define display names for brokers
export const BROKER_DISPLAY_NAMES = {
  [BROKERS.DEGIRO]: 'DEGIRO',
  [BROKERS.BUX]: 'BUX', 
  [BROKERS.BITVAVO]: 'Bitvavo',
  [BROKERS.ABN_AMRO]: 'ABN AMRO (NL)',
  [BROKERS.EVI_VAN_LANSCHOT]: 'Evi van Lanschot (NL)',
  [BROKERS.INTERACTIVE_BROKERS]: 'Interactive Brokers',
  [BROKERS.TRADING212]: 'Trading 212'
};

export const BROKER_VALUES = Object.values(BROKERS);

// Registry of all brokers and their converters
const brokerRegistry = {
  [BROKERS.DEGIRO]: {
    [CONVERTER_TYPES.TRANSACTION]: DEGIROTransactionConverter,
    [CONVERTER_TYPES.DIVIDEND]: DEGIRODividendConverter,
    [CONVERTER_TYPES.BOOKING]: DEGIROBookingConverter,
    [CONVERTER_TYPES.EXPENSE]: DEGIROExpenseConverter
  },
  [BROKERS.BUX]: {
    [CONVERTER_TYPES.TRANSACTION]: BUXTransactionConverter,
    [CONVERTER_TYPES.DIVIDEND]: BUXDividendConverter,
    [CONVERTER_TYPES.BOOKING]: BUXBookingConverter,
    [CONVERTER_TYPES.EXPENSE]: BUXExpenseConverter
  },
  [BROKERS.BITVAVO]: {
    [CONVERTER_TYPES.TRANSACTION]: BitvavoTransactionConverter,
    [CONVERTER_TYPES.DIVIDEND]: BitvavoDividendConverter,
    [CONVERTER_TYPES.BOOKING]: BitvavoBookingConverter,
    [CONVERTER_TYPES.EXPENSE]: BitvavoExpenseConverter
  },
  [BROKERS.ABN_AMRO]: {
    [CONVERTER_TYPES.TRANSACTION]: AbnAmroTransactionConverter,
    [CONVERTER_TYPES.DIVIDEND]: AbnAmroDividendConverter,
    [CONVERTER_TYPES.BOOKING]: AbnAmroBookingConverter,
    [CONVERTER_TYPES.EXPENSE]: AbnAmroExpenseConverter
  },
  [BROKERS.EVI_VAN_LANSCHOT]: {
    [CONVERTER_TYPES.TRANSACTION]: EviVanLanschotTransactionConverter,
    [CONVERTER_TYPES.DIVIDEND]: EviVanLanschotDividendConverter,
    [CONVERTER_TYPES.BOOKING]: EviVanLanschotBookingConverter,
    [CONVERTER_TYPES.EXPENSE]: EviVanLanschotExpenseConverter
  },
  [BROKERS.INTERACTIVE_BROKERS]: {
    [CONVERTER_TYPES.TRANSACTION]: InteractiveBrokersTransactionConverter,
    [CONVERTER_TYPES.DIVIDEND]: InteractiveBrokersDividendConverter,
    [CONVERTER_TYPES.BOOKING]: InteractiveBrokersBookingConverter,
    [CONVERTER_TYPES.EXPENSE]: InteractiveBrokersExpenseConverter
  },
  [BROKERS.TRADING212]: {
    [CONVERTER_TYPES.TRANSACTION]: Trading212TransactionConverter,
    [CONVERTER_TYPES.DIVIDEND]: Trading212DividendConverter,
    [CONVERTER_TYPES.BOOKING]: Trading212BookingConverter,
    [CONVERTER_TYPES.EXPENSE]: Trading212ExpenseConverter
  }
};

/**
 * Get a converter instance for a specific broker and type
 * @param {string} broker - Broker name from BROKERS enum
 * @param {string} type - Converter type from CONVERTER_TYPES enum
 * @param {string} csvContent - CSV content to convert
 * @param {Object} options - Additional options for the converter
 * @returns {BaseConverter} Converter instance
 */
export function getConverter(broker, type, csvContent, options = {}) {
  const brokerConverters = brokerRegistry[broker];
  if (!brokerConverters) {
    throw new Error(`No converters found for broker: ${broker}`);
  }

  const ConverterClass = brokerConverters[type];
  if (!ConverterClass) {
    throw new Error(`No ${type} converter found for broker: ${broker}`);
  }

  // Include the type in options
  const converterOptions = {
    ...options,
    type
  };

  return new ConverterClass(csvContent, broker, converterOptions);
}

export default brokerRegistry;
